import { IonContent, IonPage } from '@ionic/react';
import MainToolbar from '../components/MainToolbar';
import './Groups.css';

const GroupsScreen: React.FC = () => {
  return (
    <IonPage>
      <MainToolbar/>
      <IonContent fullscreen>
        <div style={{textAlign:"center", marginTop:100}}>
          Im Moment nicht verfügbar.
        </div>
      </IonContent>
    </IonPage>
  );
};

export default GroupsScreen;
