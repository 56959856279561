import { IonIcon } from '@ionic/react';
import { CSSProperties } from 'react';

interface ContainerProps {
  onClick: () => void,
  title: string,
  subtitle?: string,
  icon: string,
  relaxed?: boolean
}

const LargeButton: React.FC<ContainerProps> = (props) => {

  var buttonContainer: CSSProperties = {
    margin:25,
    marginBottom:0,
    borderRadius:10,
    padding:20, 
    display:"flex", 
    alignItems: "start",
    backgroundImage: 'linear-gradient(to right, #006DAB, #50A9E1)'
  };

  var buttonIcon: CSSProperties = {
    fontSize: 30, 
    marginRight:15, 
    color:"#ffffff",
    marginTop:-5,
  };

  var buttonTitle: CSSProperties = {
    color:"#ffffff", 
    fontSize:16, 
    fontWeight:"bold"
  };

  var buttonSubtitle: CSSProperties = {
    color:"#ffffff", 
    fontSize:13, 
    marginTop:8
  };

  if (props.relaxed) {
    buttonTitle.color = "#006DAB";
    buttonSubtitle.color = "#006DAB";
    buttonIcon.color = "#006DAB";
    buttonContainer.borderStyle = "dashed";
    buttonContainer.borderWidth = 2;
    buttonContainer.borderColor = "#ddd";
    buttonContainer.backgroundImage = 'linear-gradient(to right, #ffffff, #ffffff)';
  }

  return (
    <div 
      onClick={props.onClick}
      style={{...buttonContainer}}>
      <IonIcon 
        icon={props.icon} 
        style={{...buttonIcon}}
      />
      <div>
        <div style={{...buttonTitle}}>{props.title}</div>
        <div style={{...buttonSubtitle}}>{props.subtitle}</div>
      </div>
    </div>
  );
};

export default LargeButton;