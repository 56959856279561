import { useContext } from 'react';
import { 
  IonList, 
  IonItem, 
  IonHeader, 
  IonToolbar, 
  IonButtons, 
  IonBackButton, 
  IonPage,
  useIonModal,
  IonContent,
  IonTitle
} from '@ionic/react';
import ElementPicker from '../../components/ElementPicker';
import ElementRowItem from '../../components/ElementRow';
import RegularButton from '../../components/RegularButton';
import { SurveyElementType, SurveyWithSectionsType } from '../../data/Types';
//import { DataStore } from '../../data/DataStore';

interface ContainerProps {
  survey: SurveyWithSectionsType
  onCompleted: () => void
  onElementAdded: (element: SurveyElementType) => void
  onElementRemoved: (elementIndex: number) => void
}

const SurveyCreatorStepElements: React.FC<ContainerProps> = (props) => {

  //const { state, dispatch } = useContext(DataStore);

  const elementPickerCompleted = (photoData: string, photoTitle: string) => {
    dismiss();
    props.onElementAdded({name: photoTitle, picture: photoData});
  };

  const [present, dismiss] = useIonModal(ElementPicker, {
    onCompleted: elementPickerCompleted,
    onCancelled: () => { dismiss(); }
  });

  return (
    <IonPage>
      <IonHeader>        
        <IonToolbar style={{backgroundColor: "white"}}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/create/customize"/>
          </IonButtons>
          <IonTitle>Produkte hinzufügen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{padding:10}}>
          <IonList lines="none">
            {
              (props.survey.element.length === 0) && 
              <>
              <IonItem lines="none" style={{paddingTop: 20, paddingBottom: 20, color:"#666"}}>
                Es wurden noch keine Produkte zur Beurteilung hinzugefügt.
              </IonItem>
              <div style={{margin:10}}>
                <RegularButton
                  title="Produkt hinzufügen"
                  secondary={false}
                  onClick={()=>{
                    present()
                  }}
                />
              </div>
              </>
            }

            {
              props.survey.element.map((element, elementIndex) => {
                return (
                <ElementRowItem 
                  key={"element_"+elementIndex} 
                  element={element} 
                  onRemoveElement={()=> { props.onElementRemoved(elementIndex) }}
                />
                )
              })
            }

            {(props.survey.element.length > 0) && 
            <>
              <div style={{margin:10}}>
                <RegularButton
                  title="Weitere Produkte hinzufügen"
                  secondary={true}
                  onClick={()=>{
                    present()
                  }}
                />
              </div>
              <div style={{margin:10, marginTop:30}}>
                <RegularButton
                  title="Umfrage hochladen"
                  onClick={()=>{
                    props.onCompleted()
                  }}
                />
              </div>
            </>
            }

          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SurveyCreatorStepElements;
