import { IonIcon } from '@ionic/react';
import { starOutline, star } from 'ionicons/icons';

interface ContainerProps {
  stars: number,
  onSet: (stars: number) => void
}

const StarRating: React.FC<ContainerProps> = (props) => {

  let iconSize = 50;
  let iconStyle = {
    fontSize: iconSize,
    color: "#ddd"
  }
  let iconStyleSet = {
    fontSize: iconSize,
    color: "#337ABB"
  }

  return (
    <div style={{
      marginBottom:10, 
      marginTop: 10, 
      marginLeft:"auto", 
      marginRight: "auto", 
      width: "100%", 
      textAlign:"center",
      display: "flex",
      justifyContent: "space-between"
      }}>
      <IonIcon style={props.stars>0 ? iconStyleSet : iconStyle} icon={props.stars>0 ? star : starOutline} onClick={()=>props.onSet(1)} />
      <IonIcon style={props.stars>1 ? iconStyleSet : iconStyle} icon={props.stars>1 ? star : starOutline} onClick={()=>props.onSet(2)} />
      <IonIcon style={props.stars>2 ? iconStyleSet : iconStyle} icon={props.stars>2 ? star : starOutline} onClick={()=>props.onSet(3)} />
      <IonIcon style={props.stars>3 ? iconStyleSet : iconStyle} icon={props.stars>3 ? star : starOutline} onClick={()=>props.onSet(4)} />
      <IonIcon style={props.stars>4 ? iconStyleSet : iconStyle} icon={props.stars>4 ? star : starOutline} onClick={()=>props.onSet(5)} />
    </div>
  );
};

export default StarRating;