//import { } from '@ionic/react';

interface ContainerProps {
  title: string;
}

const MainPageTitle: React.FC<ContainerProps> = (props) => {
  return (
    <div style={{padding:15, marginTop:20, marginLeft: 17, marginBottom:20}}>
      <div style={{fontSize:22, fontWeight:100, color: "#888"}}>{props.title}</div>
    </div>
  );
};

export default MainPageTitle;