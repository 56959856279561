import { SurveyWithSectionsType } from '../data/Types';
import { useContext } from 'react';
import { NavContext, IonCard, useIonPopover, IonCardHeader, IonIcon, useIonAlert } from '@ionic/react';
import RegularButton from './RegularButton';
import { format, render, cancel, register } from 'timeago.js';
import timeAgoDe from '../helpers/DateDe';
import IconActionButton from './IconActionButton';
import FeatureNotAvailable from './empty_states/FeaturedNotAvailable';
import { DataStore } from '../data/DataStore';

import { documentTextOutline, createOutline, shareOutline, trashOutline } from 'ionicons/icons';
import { Networking } from '../helpers/Networking';

interface ContainerProps {
  survey: SurveyWithSectionsType
  onRefreshRequest?: () => void
}

const SurveyRowEditorItem: React.FC<ContainerProps> = (props) => {
  
  const { state, dispatch } = useContext(DataStore);
  const [present, dismiss] = useIonPopover(FeatureNotAvailable, { onHide: () => dismiss() });
  const [presentConfirmDelete] = useIonAlert();

  const { navigate } = useContext(NavContext);
  register("de", timeAgoDe);

  return (
    <IonCard>
      <IonCardHeader style={{paddingBottom:0}}>        
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <div style={{fontSize:16, fontWeight:"bold", color: "#111"}}>{props.survey.name}</div>
          <div>
            <IonIcon 
              onClick={() => {
                presentConfirmDelete({
                  header: 'Umfrage löschen?',
                  message: 'Die Umfrage wird mit allen Resultaten unwiderruflich entfernt, sind Sie sicher?',
                  buttons: [
                    'Abbrechen',
                    { 
                      text: 'Entfernen', 
                      handler: (d) => {
                        Networking.adminAction(state.user.token, props.survey.survey_hash, 200)
                        .then(() => {
                          if (props.onRefreshRequest) {
                            props.onRefreshRequest();
                          }
                        })
                      }
                    },
                  ],
                  onDidDismiss: () => {},
                })                
              }}
              style={{fontSize:25, color:"#3279B7"}} 
              icon={trashOutline}
            />
          </div>
        </div>
        <div style={{color:"#888", fontWeight: 400, fontSize: 13, marginTop: 0}}>
        {props.survey.element.length!==1 ? `${props.survey.element.length} Produkte` : `1 Produkt`}
        &nbsp; &middot; &nbsp;
        {props.survey ? `erstellt ${format(props.survey.added_on as Date, 'de')}` : ""}
        </div>        
      </IonCardHeader>

      <div style={{
        display:"flex", 
        justifyContent:"space-between", 
        width: "100%",
        paddingLeft: 15,
        paddingRight: 15,
        marginTop:10
      }}>
        <IconActionButton icon={documentTextOutline} title="Resultate" onClick={() => {
          navigate(`/surveys/results/${props.survey.survey_hash}`)
        }}/>
        <IconActionButton icon={createOutline} title="Bearbeiten" onClick={() => {
          present();
          setTimeout(() => { dismiss() }, 1500);
        }}/>
        <IconActionButton icon={shareOutline} title="Teilen" onClick={() => {
          navigate(`/surveys/share/${props.survey.survey_hash}`)
        }}/>
      </div>

      <div style={{margin:20}}>
      {(typeof(props.survey.responded)!=="undefined") && !props.survey.responded && 
        <RegularButton
          title="Selbst teilnehmen"
          onClick={() => {
            navigate(`/surveys/respond/${props.survey.survey_hash}`)
          }}
        />
      }
      </div>
    </IonCard>
  );
};

export default SurveyRowEditorItem;