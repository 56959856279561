import { IonSegment, IonSegmentButton } from '@ionic/react';

interface ContainerProps {
  state: boolean,
  onSet: (state: boolean) => void
}

const BooleanRating: React.FC<ContainerProps> = (props) => {

  return (
    <div style={{
      marginBottom:10, 
      marginTop: 10, 
      marginLeft:"auto", 
      marginRight: "auto", 
      width: "100%", 
      textAlign:"center",
      display: "flex",
      justifyContent: "space-between"
      }}>
      <IonSegment swipeGesture={false} value={props.state ? "yes" : "no"} onIonChange={(e) => { props.onSet(e.detail.value==="yes") }}>
        <IonSegmentButton value={"yes"}>Ja</IonSegmentButton>
        <IonSegmentButton value={"no"}>Nein</IonSegmentButton>
      </IonSegment>
    </div>
  );
};

export default BooleanRating;