import { useContext, useState } from 'react';
import { IonContent, IonPage, IonRefresher, IonRefresherContent, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';

import { DataStore } from '../data/DataStore';

import MainToolbar from '../components/MainToolbar';
import SurveyRowItem from '../components/SurveyRowItem';
import NoSurveys from '../components/empty_states/NoSurveys';
import MainPageTitle from '../components/MainPageTitle';
import Loading from '../components/empty_states/Loading';

const ResultListScreen: React.FC = () => {

  const { state, dispatch } = useContext(DataStore);
  const [ loading, setLoading ] = useState(false);

  useIonViewWillEnter(() => {
//    refreshData(); // Fetch new data right away...
  });

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
  //  refreshData();  
    setTimeout(() => {
      event.detail.complete();
    }, 500);
  }

  return (
    <IonPage>
      <MainToolbar/>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {
          loading && <Loading/>
        }
        {
          !loading && state.surveys.length === 0 && <NoSurveys/>
        }
        { !loading && state.surveys.length > 0 &&
          <div>
            <MainPageTitle title="Ergebnisse"/>
            { 
              state.surveys && state.surveys.map((e,i) => {
                return <SurveyRowItem 
                  key={"sr_"+i} 
                  survey={e}
                  link={`/surveys/results/${e.survey_hash}`}
                />
              }) 
            }
          </div>
        }
      </IonContent>
    </IonPage>
  );
};

export default ResultListScreen;
