import { IonContent, IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonIcon, IonButton, IonTitle, useIonLoading } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { reloadOutline } from 'ionicons/icons';

import { DataStore } from '../data/DataStore';
import { Networking } from '../helpers/Networking';

import StarRatingResult from '../components/StarRatingResult';
import BooleanRatingResult from '../components/BooleanRatingResult';
import CommentsResult from '../components/CommentsResult';

interface ContainerProps extends RouteComponentProps<{
  id: string;
}> {}

const ResultDetail: React.FC<ContainerProps> = ({ match }) => {

  const [results, setResults] = useState([]);
  const {state, dispatch} = useContext(DataStore);
  let survey = state.surveys.find(x => x.survey_hash === match.params.id) || state.shared_surveys.find(x => x.survey_hash === match.params.id) || null;
  const [presentLoadingNotice, hideLoadingNotice] = useIonLoading();

  const refreshData = () => {
    presentLoadingNotice("Ergebnisse laden", 1500);
    Networking.getSurveys(state.user.token)
    .then((data) => {
      data.surveys.forEach((survey) => { dispatch({type: "add_or_update_survey", payload: survey}) })
      data.shared_surveys.forEach((survey) => { dispatch({type: "add_or_update_shared_survey", payload: survey}) })
      hideLoadingNotice();
    }).catch(()=>{ hideLoadingNotice(); });
  }

  useEffect(() => {
    if (survey!==null && results.length === 0) {
      Networking.getSurveyResults(state.user.token, survey.survey_hash)
      .then((resultData) => {
        dispatch({type: "add_or_update_results", payload: resultData});
      })
    }
  }, [survey, results])

  if (!survey) {
    setTimeout(() => { refreshData(); }, 500);
    return <IonPage><IonContent><div style={{padding:50}}>Survey not found</div></IonContent></IonPage>;
  }

  const getStarRating = (sectionIndex: number, criteriaIndex: number, elementIndex: number) => {
    var rating = 0;    
    var votes = 0;
    state.results.some((result) => {
      if (survey !== null) {
        if (result.survey_hash === survey.survey_hash) {
          let ratingFound = result.summary.find(rf => rf.key === `${elementIndex}_${sectionIndex}_${criteriaIndex}`);
          if (ratingFound) {
            if (ratingFound.ratings.length > 0) {
              rating = Math.round(ratingFound.ratings.reduce((a,v,i)=>(a*i+v)/(i+1))); // Calculates Average
              votes = ratingFound.ratings.length;
              return true;  
            }
          }
        }
      }
      return false;
    });
    return {
      average_rating: rating,
      total_ratings: votes
    }
  }

  const getBooleanRating = (sectionIndex: number, criteriaIndex: number, elementIndex: number) => {
    var yes_votes = 0;  
    var no_votes = 0;  
    state.results.some((result) => {
      if (survey !== null) {
        if (result.survey_hash === survey.survey_hash) {
          let ratingFound = result.summary.find(rf => rf.key === `${elementIndex}_${sectionIndex}_${criteriaIndex}`);
          if (ratingFound) {
            yes_votes = ratingFound.ratings.filter(r => r>0).length
            no_votes = ratingFound.ratings.filter(r => r===0).length
            return true;
          }
        }
      }
      return false;
    })
    return {
      yes: yes_votes,
      no: no_votes
    }
  }  

  const getComments = (sectionIndex: number, criteriaIndex: number, elementIndex: number) => {
    var comments:string[] = [];
    state.results.some((result) => {
      if (survey !== null) {
        if (result.survey_hash === survey.survey_hash) {
          let ratingFound = result.summary.find(rf => rf.key === `${elementIndex}_${sectionIndex}_${criteriaIndex}`);
          if (ratingFound) {
            comments = ratingFound.comments;
            return true;
          }
        }
      }
      return false;
    })
    return comments;
  }  

  return (
    <IonPage>
      <IonHeader>        
        <IonToolbar style={{backgroundColor: "white"}}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/surveys"/>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={refreshData}>
              <IonIcon slot="icon-only" icon={reloadOutline} />
            </IonButton>
          </IonButtons>          
          <IonTitle>{survey.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div style={{padding:30}}>
              {
                survey?.element.map((surveyElement, elementIndex) => {
                  if (typeof(surveyElement)==="undefined") return null;
                  return (
                    <div className="survey-element" key={"survey_element_" + elementIndex}>
                      <div className="survey-element-title">
                        {surveyElement.name}
                      </div>
                      <div className="survey-element-img">
                        <img alt="" src={`https://selectifyassets.s3.amazonaws.com/elements/${surveyElement.picture}.jpg`}/>
                      </div>
                      { survey?.sections.map((surveySection, sectionIndex) => {
                        return (
                          <div className="survey-section" key={`survey_section_${elementIndex}_${sectionIndex}`}>
                            <div className="survey-section-title">{surveySection.title}</div>

                            <div className="survey-element-criteria-container">
                              {surveySection.criteria.map((c, criteriaIndex) => {                            
                                if (typeof(c)==="undefined") return (null);
                                if (!c.enabled) return (null);

                                if (c.type === "stars") {
                                  let ratingDetails = getStarRating(sectionIndex, criteriaIndex, elementIndex);
                                  return (
                                    <div className="survey-criteria-row" key={`survey_criteria_${elementIndex}_${sectionIndex}_${criteriaIndex}`}>
                                      {c.name}
                                      <div style={{paddingTop:5, paddingBottom: 5}}>
                                      <StarRatingResult stars={ratingDetails.average_rating} votes={ratingDetails.total_ratings}/>
                                      </div>
                                    </div>
                                  )
                                }

                                if (c.type === "boolean") {
                                  let ratingDetails = getBooleanRating(sectionIndex, criteriaIndex, elementIndex);
                                  return (
                                    <div className="survey-criteria-row" key={`survey_criteria_${elementIndex}_${sectionIndex}_${criteriaIndex}`}>
                                      {c.name}
                                      <div style={{paddingTop:5, paddingBottom: 5}}>
                                      <BooleanRatingResult yes={ratingDetails.yes} no={ratingDetails.no}/>
                                      </div>
                                    </div>
                                  )
                                }

                                if (c.type === "comment") {
                                  let comments = getComments(sectionIndex, criteriaIndex, elementIndex);
                                  return (
                                    <div className="survey-criteria-row" key={`survey_criteria_${elementIndex}_${sectionIndex}_${criteriaIndex}`}>
                                      {c.name}
                                      <div style={{paddingTop:5, paddingBottom: 5}}>
                                      <CommentsResult comments={comments}/>
                                      </div>
                                    </div>
                                  )
                                }

                              })}
                            </div>
                          </div> 
                        )           
                      })
                      }                            
                    </div>
                  )
                })
              }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ResultDetail;
