import React, { useEffect, useState, useRef } from 'react';
import jsQR from 'jsqr';

const PWAQR = (props) => {

  const videoRef = useRef();
  const canvasRef = useRef();
  
  const [scanning, setScanning] = useState(false);
  const [activeStream, setActiveStream] = useState(null);

  const [permissionError, setPermissionError] = useState(false);

  // Grab a frame, process it, see if we get a code!
  const frameGrabber = () => {
    if (scanning) {
      if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
        canvasRef.current.height = videoRef.current.videoHeight;
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        const imageData = canvasRef.current.getContext('2d').getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert'
        });
        if (code) {
          if (props.onSuccess) {
            props.onSuccess(code.data);
          }
        }
      }
 
    }
  };

  // Helper function to keep our timer auto-unmounting when not needed
  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  // This grabs a frame regularly to scan it...
  useInterval(frameGrabber, 1000);

  // React to changes on STREAM
  useEffect(() => {
    console.log("Stream updated...");
    if (activeStream !== null && videoRef !== null && videoRef.current) {
      videoRef.current.srcObject = activeStream;
      videoRef.current.setAttribute("playsinline", true);
      videoRef.current.setAttribute("muted", true);
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play();
        setScanning(true);
      }
    }
  }, [activeStream]);

  // COMPONENT MOUNT AND UNMOUNT
  useEffect( () => {

    // We need this in here to keep a reference, otherwise it
    // won't be available anymore in activeStream as that will be null when unmounting
    let streamReference = null;

    if (activeStream === null) {
      console.log("PWA QR Scanner activating...");
      navigator.mediaDevices.getUserMedia({audio: false, video: { frameRate: { ideal: 15, max: 25 }, width: 400, height: 400, facingMode: "environment" }})
      .then((stream) => {
        streamReference = stream;
        setActiveStream(stream);  
        setPermissionError(false);    
      }).catch((e) => {
        // TODO MORE CATCHING HERE
        console.log("ISSUE OPENING THE CAMERA", e);
        if (e.toString().indexOf("NotAllowedError")>=0) {
          setPermissionError(true);
        }
      })
    }

    // Unmounting  
    return () => {
      setScanning(false);
      setPermissionError(false);
      if (streamReference) {
        // Stop camera tracks
        streamReference.getTracks().forEach(t => t.stop());
      }
    }
  }, []);

  if (permissionError) {
    return (
      <div style={{padding: 15, border: "2px solid red", margin: 15, borderRadius: 15}}>
        Die App hat im Moment keinen Zugriff auf die Kamera. Um die notwendige Berechtigung zu erteilen,
        bitte unter iOS folgendes aktivieren: <br/><br/>
        Einstellungen &gt; Safari &gt; Kamera:<br/><br/>Dort auf von "Ablehnen" auf "Fragen" stellen. Dann die App erneut starten.
      </div>  
    )
  }

  return (
    <div id={'qr-code-full-region'} style={{backgroundColor:"white", padding: 0, paddingTop: 20, width: "100%", textAlign:"center"}}>
      <video ref={videoRef} style={{height:200, width:200, borderRadius:20, marginLeft: "auto", marginRight: "auto"}}></video>
      <canvas ref={canvasRef} style={{display:"none"}}></canvas>
    </div>
  )
};

export default PWAQR;