import { Route } from 'react-router-dom';
import { IonRouterOutlet, IonPage } from '@ionic/react';
import SurveyListScreen from './SurveyList';
import SurveyFormScreen from './SurveyForm';
import AddSurveyScreen from './AddSurvey';
import ShareSurveyScreen from './ShareSurvey';
import ResultDetail from './ResultDetail';

const SurveyScreen: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path="/surveys" component={SurveyListScreen} />
        <Route exact path="/surveys/respond/:id" component={SurveyFormScreen} />
        <Route exact path="/surveys/share/:id" component={ShareSurveyScreen} />
        <Route exact path="/surveys/results/:id" component={ResultDetail} />
        <Route exact path="/surveys/add" component={AddSurveyScreen} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default SurveyScreen;
