import { IonPage, IonContent, IonButton, IonList, IonItem, IonInput, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from '@ionic/react';
import { useState } from 'react';
import { SurveyWithSectionsType } from '../../data/Types';
import RegularButton from '../../components/RegularButton';

interface ContainerProps {
  survey: SurveyWithSectionsType,
  onSurveyUpdate: (survey: SurveyWithSectionsType) => void
}

const SurveyCreatorStepOverview: React.FC<ContainerProps> = (props) => {

  const [surveyName, setSurveyName] = useState(props.survey.name);
  
  const nextStep = () => {
    let updatedSurvey = {
      ...props.survey,
      name: surveyName
    }
    props.onSurveyUpdate(updatedSurvey);
  };

  return (
    <IonPage>
      <IonHeader>        
        <IonToolbar style={{backgroundColor: "white"}}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/create"/>
          </IonButtons>
          <IonTitle>Umfrage erstellen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{padding:10}}>
          <IonList lines="none">
            <IonItem lines="none">Titel der Umfrage</IonItem>
            <IonItem lines="none">
              <IonInput
                style={{marginBottom:20}}
                type="text"
                className=""
                value={surveyName} 
                autofocus={true}
                placeholder=""
                onIonChange={e => { setSurveyName(e.detail.value!) } }></IonInput>
            </IonItem>
          </IonList>
          
          <div style={{margin:10}}>
            <RegularButton onClick={() => { nextStep() }} title="Weiter"/>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SurveyCreatorStepOverview;
