import { CSSProperties } from "react";

interface ContainerProps {
  comments: string[],
}

const CommentsResult: React.FC<ContainerProps> = (props) => {
  const commentStyle:CSSProperties = {
    fontStyle: "italic",
    color: "#555",
    marginBottom:5,
    fontSize: 14,

  }
  return (
    <div style={{...commentStyle}}>
      {
        props.comments.length === 0 && 
        <div>
          Keine Kommentare
        </div>
      }
      {
        props.comments.length > 0 && 
        <ul style={{marginLeft:0, paddingLeft:20}}>
          {
            props.comments.map((comment,i) => {
              return (
                <li style={{...commentStyle}} key={`comment_${i}`}>
                  {comment}
                </li>
              )
            })
          }
        </ul>
      }
    </div>
  )
};

export default CommentsResult;
