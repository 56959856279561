import { IonPage, IonContent, IonSpinner  } from '@ionic/react';

interface ContainerProps {
  onCompleted: (success: boolean) => void
}

const SurveyCreatorStepUploading: React.FC<ContainerProps> = (props) => {
  return (
    <IonPage>
      <IonContent>
        <div style={{padding:10, textAlign: "center", marginTop:200}}>
        <IonSpinner color="primary" name="crescent" /><br/><br/>
          Umfrage wird hochgeladen
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SurveyCreatorStepUploading;
