import { 
  IonButton,
  IonButtons, 
  IonHeader, 
  IonToolbar, 
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonIcon,
  IonTitle
} from '@ionic/react';
import { CSSProperties, useEffect, useState } from 'react';
import { usePhotoGallery } from '../helpers/Photos';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { imageOutline, trashOutline } from 'ionicons/icons';
import RegularButton from './RegularButton';

interface ContainerProps {
  onCancelled: () => void,
  onCompleted: (photoData: string, photoTitle: string) => void
}

const ElementPicker: React.FC<ContainerProps> = (props) => {

  defineCustomElements(window);

  const { takePhoto } = usePhotoGallery();
  const [ photoTitle, setPhotoTitle ] = useState("");
  const [ photo, setPhoto ] = useState("");
  const [ hasAllRequiredData, allRequiredDataAvailable ] = useState(false);

  const previewImageStyle:CSSProperties = {
    marginLeft:"auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 600,
    height: "20vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center",
  }

  useEffect(() => {
    var hasAllData = true;
    if (photo==="") hasAllData = false;
    if (photoTitle.trim()==="") hasAllData = false;
    allRequiredDataAvailable(hasAllData);
  }, [photo, photoTitle])

  const pickPhoto = () => {
    takePhoto().then((data) => {
      //console.log(data);
      setPhoto(data as string);
    }).catch((error) => {
      console.log("Photo error", error);
    })
  };

  return (
    <IonPage>
      <IonHeader>        
      <IonToolbar style={{backgroundColor: "white"}}>
          <IonTitle>Produkt hinzufügen</IonTitle>
          <IonButtons slot="end">
          <IonButton expand="block" onClick={()=>props.onCancelled()}>
            Abbrechen
          </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{padding:10}}>
          <IonList lines="none">
            <IonItem lines="none">Produktbild</IonItem>
            <IonItem lines="none">
              {(photo==="") && 
              <div style={{display:"flex",alignItems:"center"}}>
                <IonIcon 
                  style={{fontSize:100, color:"#999"}} 
                  icon={imageOutline}
                  onClick={pickPhoto}
                />
                <IonButton
                  expand="block"
                  fill="clear"
                  onClick={pickPhoto}>Bild hinzufügen</IonButton>
              </div>
              }
              {(photo!=="") && 
              <div style={{width:"100%", marginBottom:20, textAlign:"center"}}>
                <div style={{...previewImageStyle, backgroundImage: `url(${photo})`}}></div>
                <IonButton 
                  onClick={()=>{ setPhoto(""); }}
                  size="large" 
                  fill="clear">
                    <IonIcon style={{fontSize:30, color:"#dd0000"}} icon={trashOutline}/>
                </IonButton>
              </div>}
            </IonItem>
            <IonItem lines="none">Kurze Produktbezeichnung</IonItem>
            <IonItem lines="none">
              <IonInput
                type="text"
                className="single-line-text-input"
                value={photoTitle} 
                autofocus={false}
                placeholder=""
                onIonChange={e => { setPhotoTitle(e.detail.value!) } }></IonInput>
            </IonItem>

            <div style={{margin:10}}>
              <RegularButton
                title="Produkt hinzufügen"
                disabled={!hasAllRequiredData}
                onClick={()=>props.onCompleted(photo, photoTitle)}
              />
            </div>

          </IonList>
        </div>       
      </IonContent>
    </IonPage>
  );
};

export default ElementPicker;