import { IonIcon } from '@ionic/react';
import { thumbsUp, thumbsDown } from 'ionicons/icons';
import { CSSProperties } from 'react';

interface ContainerProps {
  yes: number,
  no: number
}

const BooleanRatingResult: React.FC<ContainerProps> = (props) => {

  const voteCountBoxStyle:CSSProperties = {
    marginLeft: 10, 
    borderStyle: "solid", 
    borderColor: "#ddd", 
    borderWidth: 1, 
    padding: 6, 
    borderRadius: 10, 
    minWidth: 60, 
    textAlign: "center"
  };

  return (
    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
        <IonIcon icon={thumbsUp} style={{color:"#007bc0", fontSize:25}}/>
        <div style={{...voteCountBoxStyle}}>{props.yes}</div>
      </div>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginLeft:40}}>
        <IonIcon icon={thumbsDown} style={{fontSize:25, color: "#ee0000"}}/>
        <div style={{...voteCountBoxStyle}}>{props.no}</div>
      </div>
    </div>
  )
};

export default BooleanRatingResult;
