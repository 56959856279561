import { Storage } from '@ionic/storage';

export type SurveyCriteriaType = {
  name: string
  type?: string
  enabled?: boolean
  id?: string
}

export type SurveyElementType = {
  name: string,
  picture: string
}

export type SurveyComment = {
  elementIndex: number
  comment: string,
}

export type SurveySectionType = {
  title: string,
  criteria: SurveyCriteriaType[],
  enabled?: boolean
}

export type SurveyWithSectionsType = {
  created_at?: Date,
  responded?: boolean,
  survey_hash: string
  short_hash?: string,
  added_on?: Date,
  name: string,
  author: string,
  sections: SurveySectionType[],
  element: SurveyElementType[],
  ratings: SurveyResponseItem[],
  open: boolean
}

export type SurveyRequestItem = {
  responded: boolean,
  survey_hash: string,
  short_hash: string,
  created_at: number  
}

export type SurveyRequest = {
  surveys: SurveyRequestItem[],
  surveys_shared: SurveyRequestItem[]
}

export const SurveyWithSectionsTypeInitialState = {
  survey_hash: "",
  short_hash: "",
  name: "",
  author: "",
  sections: [] as SurveySectionType[],
  element: [] as SurveyElementType[],
  ratings: [],
  open: false,
}

export type SurveyResponseItem = {
  sectionIndex: number,
  elementIndex: number,
  criteriaIndex: number,
  type: string,
  rating: number,
  comment: string,
}

export type SurveyRatingCriteriaPayload = {
  criteria: string,
  rating: number
}

export type SurveyRatingPayload = {
  element: string,
  comment: string,
  criteria: SurveyRatingCriteriaPayload[]
}

export type SurveyPayload = {
  name: string,
  author: string,
  rating: SurveyRatingPayload[]
}

export interface DataStoreDispatchAction {
  type: string;
  payload?: any;
}

export type DataStoreType = {
  store: Storage,
  initialized: Boolean,
  user: {
    token: string,
    loggedIn: Boolean,
    isEditor: Boolean
  },
  surveys: SurveyWithSectionsType[],
  shared_surveys: SurveyWithSectionsType[],
  templates: TemplateType[],
  results: SurveyResultsData[],
  build: string
}

export type TemplateType = {
  author_id?: string,
  id: string,
  enabled: boolean,
  template_name: string,
  sections: SurveySectionType[]
}

export interface SurveyCreatorDispatchAction {
  type: string,
  payload?: any
}

export type SurveyCreatorStateType = {
  survey: SurveyWithSectionsType,
  creation_state: number,
  uploading: boolean
}

export type S3UploadPair = {
  file_name: string
  url: string
}

export type S3UploadAgreement = {
  data: S3UploadPair[]  
}


export type SurveyResultsAggregateRow = {
  key: string,
  type: string,
  ratings: Array<number>,
  comments: Array<string>
}

export type SurveyResultsData = {
  status?: boolean,
  survey_hash: string,
  results:  Array<any>,
  summary: SurveyResultsAggregateRow[]
}

export type CriteriaCustomizingModalPayloadData = {
  mode: string
  criteriaIndex: number
  sectionIndex: number
}

export const CriteriaCustomizingModalPayloadDataInitialState = {
  mode: "create",
  criteriaIndex: 0,
  sectionIndex: 0
}