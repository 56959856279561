import { SurveyElementType } from '../data/Types';
import { IonCard, IonCardHeader, IonCardContent, IonIcon } from '@ionic/react';
import { CSSProperties, useContext } from 'react';
import { trashOutline } from 'ionicons/icons';

interface ContainerProps {
  element: SurveyElementType,
  onRemoveElement?: () => void
}

const ElementRowItem: React.FC<ContainerProps> = (props) => {

  const previewImageStyle:CSSProperties = {
    marginLeft:"auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 600,
    height: "20vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center center",
  }

  return (
    <IonCard>
      <IonCardHeader>
        <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}}>
          <div style={{color:"black"}}>{props.element.name}</div>
          <div>
            <IonIcon 
              onClick={props.onRemoveElement}
              style={{fontSize:25, color:"#dd0000"}} 
              icon={trashOutline}
            />
          </div>
        </div>
      </IonCardHeader>
      <IonCardContent>
        <div style={{...previewImageStyle, backgroundImage: `url(${props.element.picture})`}}></div>
      </IonCardContent>
    </IonCard>
  );
};

export default ElementRowItem;