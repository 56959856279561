import React from 'react';
import { IonSpinner } from '@ionic/react';

interface ContainerProps {
  text?: string
}

const Loading: React.FC<ContainerProps> = (props) => {
  return (
    <div style={{padding:30, textAlign:"center"}}>
      <IonSpinner name="crescent" />
      <div style={{marginTop:30, color:"#666"}}>{props.text ? props.text : ""}</div>
    </div>
  );
};

export default Loading;