//import { useState, useEffect } from 'react';
//import { isPlatform } from '@ionic/react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import Jimp from 'jimp';
import { Networking } from './Networking';

export const usePhotoGallery = () => {

  const takePhoto = () => {
    return new Promise((resolve, reject) => {
      Camera.getPhoto({
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Prompt,
        quality: 90,
        width: 1024
      }).then((cameraPhoto) => {
        Networking.blobFromPath(cameraPhoto.webPath!)
        .then((blob) => {
          blob.arrayBuffer()
          .then((buffer) => {
            Jimp.read(Buffer.from(buffer))
            .then((image) => {
              image
                .scaleToFit(1024, 1024)
                .quality(90)
                .getBase64Async(Jimp.MIME_JPEG)
                .then((b64File) => {
                  resolve(b64File);
                }).catch((error) => { reject(); })
              }).catch((error) => { reject(); })    
            }).catch((error) => { reject(); });    
          })
        }).catch((error) => { reject(); });
    });
  };

  return {
    takePhoto
  };
}