import { TemplateType } from '../data/Types';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';

interface ContainerProps {
  template: TemplateType
  onClick: () => void,
}

const calculateCriteriaCount = (template: TemplateType): number => {
  var t = 0;
  template.sections.forEach((e,i) => {
    t += e.criteria.length;
  })
  return t;
}

const SurveyTemplateRowItem: React.FC<ContainerProps> = ({ template, onClick }) => {
  return (
    <IonCard onClick={onClick}>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <IonCardHeader>
          <IonCardTitle style={{fontSize:20, fontWeight:"normal"}}>{template.template_name}</IonCardTitle>
          <IonCardSubtitle style={{fontWeight:"normal"}}>{calculateCriteriaCount(template)} Kriterien</IonCardSubtitle>
        </IonCardHeader>
        <div style={{fontSize:20, marginTop: 5, paddingRight:15}}>
          <IonIcon icon={chevronForwardOutline}/>
        </div>
      </div>
    </IonCard>
  );
};

export default SurveyTemplateRowItem;