import { useContext, useState } from 'react';
import {
  NavContext,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  useIonViewWillEnter
} from '@ionic/react';

import { checkboxOutline, add } from 'ionicons/icons';

import { RefresherEventDetail } from '@ionic/core';

import { DataStore } from '../data/DataStore';
import { Networking } from '../helpers/Networking';

import MainToolbar from '../components/MainToolbar';
import SurveyRowEditorItem from '../components/SurveyRowEditorItem';
import NoSurveys from '../components/empty_states/NoSurveys';
import SurveyRowItem from '../components/SurveyRowItem';
import NoSurveysCreatedYet from '../components/empty_states/NoSurveysCreatedYet';
import LargeButton from '../components/LargeButton';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import { SurveyWithSectionsType } from '../data/Types';

const SurveyListScreen: React.FC = () => {

  const { navigate } = useContext(NavContext);
  const { state, dispatch } = useContext(DataStore);
  const [showDragToRefresh, setDragToRefreshInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewSection, setViewSection] = useState("shared");

  const refreshData = () => {
    setLoading(true);
    dispatch({ type: "update_surveys", payload: []});
    Networking.getSurveys(state.user.token)
      .then((data) => {
        data.surveys.forEach((survey) => { dispatch({ type: "add_or_update_survey", payload: survey }) })
        data.shared_surveys.forEach((survey) => { dispatch({ type: "add_or_update_shared_survey", payload: survey }) })
        setLoading(false);
      }).catch(() => { setLoading(false) });
  }

  useIonViewWillEnter(() => {
    refreshData(); // Fetch new data right away...
    // Check if we're supposed to show our own surveys first
    if (document.location.search.indexOf("?own") >= 0) {
      setViewSection("own");
      window.history.replaceState(null, "", "/surveys");
    }
  });

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setDragToRefreshInfo(false);
    refreshData();
    setTimeout(() => {
      event.detail.complete();
    }, 500);
  }

  const getSortedSurveys = (surveys: SurveyWithSectionsType[]): SurveyWithSectionsType[] => {
    return [...surveys].sort((a, b) => {
      if (typeof (a.added_on) === "undefined") return 0;
      if (typeof (b.added_on) === "undefined") return 0;
      return (b.added_on!.getTime() as number) - ((a.added_on!.getTime() as number) || 0)
    });
  }

  return (
    <IonPage>
      <MainToolbar>
        <IonToolbar>
          <IonSegment value={viewSection} onIonChange={(e) => { setViewSection(e.detail.value || "shared") }}>
            <IonSegmentButton value={"shared"}>Alle Umfragen</IonSegmentButton>
            <IonSegmentButton value={"own"}>Meine Umfragen</IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </MainToolbar>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent refreshingSpinner={null}></IonRefresherContent>
        </IonRefresher>

        {viewSection === "shared" &&
          <>
            <LargeButton
              icon={checkboxOutline}
              relaxed={loading || state.shared_surveys.length > 0}
              title="An Umfrage teilnehmen"
              subtitle="Jetzt Einladungs-Code eingeben oder scannen und an Umfrage teilnehmen."
              onClick={() => {
                navigate("/surveys/add")
              }}
            />

            {!loading && state.shared_surveys.length /*.filter(s=>!s.responded).length */ === 0 && <NoSurveys showInfo={showDragToRefresh} />}

            {!loading &&
              <>
                {state.shared_surveys && state.shared_surveys.length > 0 &&
                  <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
                    {
                      getSortedSurveys(state.shared_surveys).map((e, i) => {
                        return <SurveyRowItem key={"sr_" + i} survey={e} link={`/surveys/respond/${e.survey_hash}`} />
                      })
                    }
                  </div>
                }
              </>
            }
          </>
        }

        {viewSection === "own" &&
          <>

            <LargeButton
              icon={add}
              relaxed={loading || state.surveys.length > 0}
              title="Neue Umfrage erstellen"
              subtitle="Aus einer Vorlage oder mit eigenen Kriterien Umfrage starten und teilen."
              onClick={() => {
                navigate("/create")
              }}
            />

            {!loading && state.surveys.length === 0 &&
              <NoSurveysCreatedYet />
            }
            {!loading && state.surveys.length > 0 &&
              <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
                {
                  state.surveys && getSortedSurveys(state.surveys).map((e, i) => {
                    return (
                    <SurveyRowEditorItem
                      onRefreshRequest={()=>{ refreshData(); }}
                      survey={e}
                      key={"sr_" + i}  
                    />
                    )
                  })
                }
              </div>
            }
          </>
        }

        {
          loading &&
          <div style={{ paddingTop: 10 }}>
            <LoadingPlaceholder />
            <LoadingPlaceholder />
            <LoadingPlaceholder />
          </div>
        }
      </IonContent>
    </IonPage>
  );
};

export default SurveyListScreen;
