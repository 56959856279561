import { useContext } from 'react';
import { SurveyWithSectionsType } from '../data/Types';
import { IonCard, IonCardHeader, NavContext, IonIcon, useIonAlert } from '@ionic/react';
import { DataStore } from '../data/DataStore';
import { format, render, cancel, register } from 'timeago.js';
import timeAgoDe from '../helpers/DateDe';
import RegularButton from './RegularButton';
import { Networking } from '../helpers/Networking';
import { trashOutline } from 'ionicons/icons';

interface ContainerProps {
  survey: SurveyWithSectionsType
  link: string,
}

const SurveyRowItem: React.FC<ContainerProps> = ({ survey, link }) => {

  const { state, dispatch } = useContext(DataStore);
  const { navigate } = useContext(NavContext);
  const [presentConfirmDelete] = useIonAlert();

  register("de", timeAgoDe);

  return (
    <IonCard>
      <IonCardHeader style={{paddingBottom:0}}>        
      <div style={{display:"flex", justifyContent:"space-between"}}>
          <div style={{fontSize:16, fontWeight:"bold", color: "#111"}}>{survey.name}</div>
          <div>
            <IonIcon 
              onClick={() => {
                presentConfirmDelete({
                  header: 'Umfrage löschen?',
                  message: 'Die Umfrage wird entfernt, kann aber jederzeit wieder hinzugefügt werden.',
                  buttons: [
                    'Abbrechen',
                    { 
                      text: 'Entfernen', 
                      handler: (d) => {
                        Networking.adminAction(state.user.token, survey.survey_hash, 201)
                        .then(() => {
                          document.location.reload();
                        })
                      }
                    },
                  ],
                  onDidDismiss: () => {},
                })                
              }}
              style={{fontSize:25, color:"#3279B7"}} 
              icon={trashOutline}
            />
          </div>
        </div>
        <div style={{color:"#888", fontWeight: 400, fontSize: 13, marginTop: 0}}>
        {survey.element.length!==1 ? `${survey.element.length} Produkte` : `1 Produkt`}
        &nbsp; &middot; &nbsp;
        {survey ? `angenommen ${format(survey.added_on as Date, 'de')}` : ""}
        </div>        
      </IonCardHeader>

      {
        !survey.responded &&
        <div style={{margin:20}}>
          <RegularButton 
            title="Jetzt teilnehmen"
            onClick={() => {
              navigate(link)
            }}
          />
        </div>
      }

      {
        survey.responded &&
        <div style={{margin:20}}>
          <RegularButton 
            title="Resultate ansehen"
            onClick={() => {
              navigate(`/surveys/results/${survey.survey_hash}`)
            }}
          />
        </div>
      }      

    </IonCard>
  );
};

export default SurveyRowItem;