import { IonIcon } from '@ionic/react';

interface ContainerProps {
  onClick: () => void,
  title: string,
  icon: string
}

const IconActionButton: React.FC<ContainerProps> = (props) => {
  return (
    <div 
      onClick={props.onClick}
      style={{
        marginLeft:5,
        marginRight:5,
        marginBottom:2,
        borderRadius:10,
        padding:10, 
        backgroundColor: "#f4f4f4",
        textAlign: "center",
        width:"100%"        
      }}>
      <IonIcon 
        icon={props.icon} 
        style={{
          fontSize: 20, 
          color:"#3279B7",
        }}
      />
      <div style={{color:"#3279B7", fontSize:12, fontWeight:"bold"}}>{props.title}</div>
    </div>
  );
};

export default IconActionButton;