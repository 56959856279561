import React from 'react';
import { Storage } from '@ionic/storage';
import {
  DataStoreType,
  DataStoreDispatchAction,
  SurveyWithSectionsType,
  SurveyResultsData
} from './Types';

export const DataStoreInitialState = {    
  store: new Storage(),
  initialized: false,
  user: {
    token: "",
    loggedIn: false,
    isEditor: true,
  },
  surveys: [],
  shared_surveys: [],  
  templates: [],
  results: [],
  build: '20211124141556685',
}

export const DataStoreReducer = (state: DataStoreType, action: DataStoreDispatchAction) => {  
  console.log(`Data Store Operation: [${action.type}] with payload:`, action.payload);
  switch (action.type) {
    case "reset":
      return DataStoreInitialState
    case "storage_initialized":
      return {...state, initialized: true}
    case "mark_user_authenticated":
      return {...state, user: action.payload }
    case "mark_user_deauthenticated":
      return {...state, user: { loggedIn: false, token: "", isEditor: false } }
    case "update_surveys":
      return {...state, surveys: action.payload }
    case "update_templates":
      return {...state, templates: action.payload }
    case "add_or_update_survey":
      // Check if we have this exact survey record already...
      var recordAlreadyExisted = false;
      var updatedSurveys = state.surveys.map((survey) => {
        if (survey.survey_hash === (action.payload as SurveyWithSectionsType).survey_hash) {
          recordAlreadyExisted = true;
          return (action.payload as SurveyWithSectionsType)
        } else {
          return survey
        }
      });
      if (!recordAlreadyExisted) updatedSurveys.push((action.payload as SurveyWithSectionsType));      
      return {...state, surveys: updatedSurveys}
    case "add_or_update_shared_survey":
      // Check if we have this exact survey record already...
      var recordAlreadyExisted = false;
      var updatedSurveys = state.shared_surveys.map((survey) => {
        if (survey.survey_hash === (action.payload as SurveyWithSectionsType).survey_hash) {
          recordAlreadyExisted = true;
          return (action.payload as SurveyWithSectionsType)
        } else {
          return survey
        }
      });
      if (!recordAlreadyExisted) updatedSurveys.push((action.payload as SurveyWithSectionsType));      
      return {...state, shared_surveys: updatedSurveys}      
    case "add_or_update_results": 
      // Check if we have this exact result record already...
      var recordAlreadyExisted = false;
      var updatedResults = state.results.map((resultRecord) => {
        if (resultRecord.survey_hash === (action.payload as SurveyResultsData).survey_hash) {
          recordAlreadyExisted = true;
          return (action.payload as SurveyResultsData)
        } else {
          return resultRecord
        }
      });
      if (!recordAlreadyExisted) updatedResults.push((action.payload as SurveyResultsData));      
      return {...state, results: updatedResults}
    default:
      return state
  }
}

export const DataStore = React.createContext<{
  state: DataStoreType,
  dispatch: React.Dispatch<DataStoreDispatchAction>
  } > ({
    state: DataStoreInitialState,
    dispatch: () => undefined,
});