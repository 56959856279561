import { IonHeader,IonToolbar, IonImg } from '@ionic/react';

type ContainerProps = {
  children?: React.ReactNode,
  onClick?: () => void
}

const MainToolbar: React.FC<ContainerProps> = (props) => {
  return (
    <IonHeader onClick={props.onClick} className="no-border-please">
    <IonToolbar style={{height:5}}></IonToolbar>
    <IonToolbar style={{height:60, backgroundColor: "white"}}>
      <IonImg src={"assets/hselect-logo.png"} style={{height:20, marginTop:15}}/>
    </IonToolbar>
    {props.children}
  </IonHeader>
  );
};

export default MainToolbar;