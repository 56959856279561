import { useState, useRef, useEffect } from 'react';
import { 
  IonButton, 
  IonList, 
  IonItem, 
  IonTitle,
  IonItemDivider,
  IonLabel,
  IonHeader, 
  IonToolbar, 
  IonButtons, 
  IonBackButton, 
  IonPage,
  IonContent,
  IonAlert,
  IonIcon,
  IonModal,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  useIonAlert,
  createAnimation
} from '@ionic/react';
import { SurveyCriteriaType, SurveyWithSectionsType } from '../../data/Types';
import { trashOutline, createOutline, ellipsisVertical } from 'ionicons/icons';
import RegularButton from '../../components/RegularButton';
import CriteriaEditor from '../../components/CriteriaEditor';

interface ContainerProps {
  router: HTMLIonRouterOutletElement | null;
  survey: SurveyWithSectionsType,
  onShowCriteriaEditor: (payload: any) => void
  onCompleted: () => void
  toggleCriteria: (criteria: SurveyCriteriaType) => void
  addSection?: (section: string) => void
  renameSection: (sectionIndex: number, sectionName: string) => void
  removeCriteria: (sectionIndex: number, criteriaIndex: number) => void
  //replaceCriteria: (sectionIndex: number, criteriaIndex: number, criteria: SurveyCriteriaType) => void
  //addCriteria: (sectionIndex: number, criteria: SurveyCriteriaType) => void
}

const SurveyCreatorStepCustomize: React.FC<ContainerProps> = (props) => {

  const [sectionDialogVisible, setSectionDialog] = useState(false);
  const [editingDialogOpen, setEditingDialog] = useState(false);
  const [editingSectionDialog, setEditingSectionDialog] = useState(false);

  const [sectionTitleToBeEdited, setSectionTitleToBeEdited] = useState("");
  const [sectionIndexToBeEdited, setSectionIndexToBeEdited] = useState(0);

  const [criteriaIndexToBeEdited, setCriteriaIndexToBeEdited] = useState(-1);

  const [presentConfirmDelete] = useIonAlert();

  const slidingCriteriaItems = useRef(null);

  const hasMinimumData = (survey: SurveyWithSectionsType) => {
    var foundEnough = false;
    if (survey.sections) {
      survey.sections.forEach((ss,i) => {
        if (ss.criteria.length>0) foundEnough = true;
      })
    }
    return foundEnough;
  }

  const enterAnimation = (baseEl: any) => {
    const backdropAnimation = createAnimation()
      .addElement(baseEl.querySelector('ion-backdrop')!)
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

    const wrapperAnimation = createAnimation()
      .addElement(baseEl.querySelector('.modal-wrapper')!)
      .keyframes([
        { opacity: '0.80', transform: 'scale(0.8)' },
        { opacity: '0.99', transform: 'scale(1)' }
      ]);

    return createAnimation()
      .addElement(baseEl)
      .easing('linear')
      .duration(300)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  }

  return (
    <>
    {
      /*
      editingDialogOpen &&
      <IonModal
        enterAnimation={enterAnimation}
        animated={true}
        presentingElement={props.router || undefined}
        //showBackdrop={false}
        isOpen={editingDialogOpen}
        swipeToClose={true}
        onDidDismiss={() => setEditingDialog(false) }
      >
        <CriteriaEditor
          criteriaIndex={criteriaIndexToBeEdited}
          sectionIndex={sectionIndexToBeEdited}
          sections={props.survey.sections}          
          onCancelled={() => { setEditingDialog(false); }}
          onCompleted={(surveyIndex, newCriteria) => {
            props.addCriteria(surveyIndex, newCriteria); 
            setEditingDialog(false); 
          }}
          onRemoved={() => {
            props.removeCriteria(sectionIndexToBeEdited, criteriaIndexToBeEdited);
            setEditingDialog(false); 
          }}
          onChanged={(newCriteriaData) => {
            props.replaceCriteria(sectionIndexToBeEdited, criteriaIndexToBeEdited, newCriteriaData);
            setEditingDialog(false); 
          }}
        />
      </IonModal>
      */
    }   

    <IonPage>         
      <IonHeader>        
        <IonToolbar style={{backgroundColor: "white"}}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/create/overview"/>
          </IonButtons>
          <IonTitle>Kriterien anpassen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonAlert
          isOpen={sectionDialogVisible}
          onDidDismiss={() => {
            setSectionDialog(false);
          }}
          header="Name des Abschnitts"
          inputs={
            [
              {
                name: "section",
                placeholder: "Beispiel: Allgemeine Fragen"
              }
            ]
          }
          buttons={[
            {
              text: 'Abbrechen',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                setSectionDialog(false);
              }
            },
            {
              text: 'OK',
              handler: (e) => {
                setSectionDialog(false);
              }
            }
          ]
          }
        />

        <IonAlert
          isOpen={editingSectionDialog}
          onDidDismiss={() => {
            setEditingSectionDialog(false);
          }}
          header="Name des Abschnitts"
          inputs={
            [
              {
                name: "text",
                value: sectionTitleToBeEdited,
              }
            ]
          }
          buttons={[
            {
              text: 'Abbrechen',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                setEditingSectionDialog(false);
              }
            },
            {
              text: 'OK',
              handler: (e) => {
                props.renameSection(sectionIndexToBeEdited, e.text);
                setEditingSectionDialog(false);
              }
            }
          ]
          }
        />        


        <div style={{padding:10}}>
          <IonList lines="none" ref={slidingCriteriaItems}>
            <IonItem lines="none">
              <div style={{paddingBottom: 20, paddingTop: 20, color: "#666"}}>
                {
                props.survey?.sections.length === 0 ? 
                  "Welche Kriterien soll die Umfrage enthalten?" :
                  "Welche Kriterien sollen in die Umfrage übernommen werden?"
                }
              </div>
            </IonItem>

            {props.survey.sections.map((section, sectionIndex) => {   
              if (section.criteria.length === 0 && props.survey.sections.length>1) return (null);           
              return (
                <div key={"section_"+sectionIndex}>
                  {
                    (section.title!=="") && 
                    <IonItemDivider onClick={()=>{ 
                        setSectionTitleToBeEdited(section.title); 
                        setSectionIndexToBeEdited(sectionIndex);
                        setEditingSectionDialog(true);
                    }}>
                      <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                        <div>{section.title}</div>
                        <IonIcon icon={createOutline} style={{color: "#007BC0", fontSize:30, marginRight: 0, marginTop: 10, marginBottom: 10}}/>
                      </div>
                    </IonItemDivider>               
                  }
                  {
                    section.criteria.map((criteria, criteriaIndex) => {
                      return (

                        <IonItemSliding key={"criteria_"+criteriaIndex+"_"+sectionIndex}>

                          <IonItem onClick={()=>{ 
                            props.onShowCriteriaEditor({
                              mode: "edit",
                              criteriaIndex: criteriaIndex,
                              sectionIndex: sectionIndex,
                            });
                          }}>

                            <IonLabel>
                              {criteria.name}<br/>
                              <span style={{fontSize:15, color: "#666"}}>
                              {
                                criteria.type === "boolean" && "Ja/Nein Antwort"
                              }
                              {
                                criteria.type === "stars" && "5-Punkte Bewertung"
                              }
                              {
                                criteria.type === "comment" && "Kommentarfeld"
                              }
                              </span>
                            </IonLabel>

                            <IonButton
                              size="default"
                              style={{marginRight:-15}}
                              fill="clear"
                              onClick={()=>{                                
                              }}
                            >
                              <IonIcon icon={ellipsisVertical}/>
                            </IonButton>
                          </IonItem>

                          <IonItemOptions>
                            <IonItemOption color="danger" onClick={()=>{
                              presentConfirmDelete({
                                header: 'Kriterium entfernen?',
                                message: 'Sie entfernen das Kriterium unwiderruflich aus dieser Umfrage.',
                                buttons: [
                                  'Abbrechen',
                                  { 
                                    text: 'Entfernen', 
                                    handler: (d) => {
                                      props.removeCriteria(sectionIndex, criteriaIndex);
                                      document.querySelector("ion-item-sliding")?.closeOpened();
                                    }
                                  },
                                ],
                                onDidDismiss: () => {
                                  document.querySelector("ion-item-sliding")?.closeOpened();
                                },
                              }) 
                            }}>
                              <IonIcon slot="start" style={{fontSize:20}} icon={trashOutline}></IonIcon>
                            </IonItemOption>
                          </IonItemOptions>
                      </IonItemSliding>
                      )
                    })
                  }
                </div>
              )
            })}

            {
              !editingDialogOpen && 
              <div style={{margin:10, marginBottom:20}}>
                <RegularButton
                  title="Kriterium hinzufügen"
                  onClick={()=>{

                    //setCriteriaIndexToBeEdited(-1);
                    //setSectionIndexToBeEdited(-1);
                    //if (props.router) props.router.animated = false;                    
                    //setEditingDialog(true);
                    props.onShowCriteriaEditor({
                      mode: "create",
                      criteriaIndex: 0,
                      sectionIndex: 0,
                    });

                  }}
                  secondary={true}
                />
              </div>
            }
            
            <div style={{margin:10}}>
              <RegularButton 
                onClick={() => { props.onCompleted() }} 
                title="Weiter"
                disabled={!hasMinimumData(props.survey)}
              />
            </div>

          </IonList>
        </div>

      </IonContent>
    </IonPage>
    </>
  );
};

export default SurveyCreatorStepCustomize;
