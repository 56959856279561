import { IonIcon, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import { createOutline } from 'ionicons/icons';

const NoSurveysCreatedYet: React.FC = () => {

  let iconSize = 70;
  let iconStyle = {
    fontSize: iconSize,
    color: "#337ABB",
    marginTop:-50
  }

  return (
    <IonGrid style={{marginTop:0}}>
      <IonRow style={{}}>
        <IonCol size="12">
          <div style={{width: "100%", textAlign:"center"}}>
            <div style={{marginTop:15, color: "#666"}}>
            Es wurden noch keine Umfragen erstellt.
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default NoSurveysCreatedYet;