import { Route } from 'react-router-dom';
import { IonRouterOutlet, IonPage } from '@ionic/react';
import ResultListScreen from './ResultList';
import ResultDetail from './ResultDetail';


/*
  TODO --- THIS IS NOT IN USE. THE RESULT PAGE HAS MOVED TO /surveys/results
*/


const ResultsScreen: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path="/results" component={ResultListScreen} />
        <Route exact path="/results/details/:id" component={ResultDetail} />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ResultsScreen;
