import { useContext, useState } from 'react';
import { NavContext, IonButton, useIonLoading, useIonAlert, IonAlert } from '@ionic/react';
import { DataStore } from '../data/DataStore';
import { Networking } from '../helpers/Networking';
import { User } from '../helpers/User';

const AdminMenu: React.FC = () => {
  const { state, dispatch } = useContext(DataStore);
  const [presentLoadingNotice, hideLoadingNotice] = useIonLoading();
  const [showCustomCode, setShowCustomCode] = useState(false);
  const {navigate} = useContext(NavContext);

  const adminAction = (action: number) => {
    presentLoadingNotice("Funktion wird ausgeführt.", 1500);
    Networking.adminAction(state.user.token, "", action)
    .then(() => {
      // Some actions require a logout. Let's log out if we know
      // it's one of those actions (range 100 to 199)
      if (action >= 100 && action < 200) {
        User.logout(state, dispatch).then(() => { navigate("/"); });
      }
    });
  }

  return (
    <div style={{padding:30, marginTop:-30}}>
      <h4>User-Test Administration</h4>

      <IonAlert
        isOpen={showCustomCode}
        onDidDismiss={() => {
          setShowCustomCode(false);
        }}
        header="Action Code eingeben:"
        inputs={
          [
            {
              name: "code",
            }
          ]
        }
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              setShowCustomCode(false);
            }
          },
          {
            text: 'Ok',
            handler: (e) => {
              console.log(e);
              adminAction(e.code);
              setShowCustomCode(false);
            }
          }
        ]}
      />
      <IonButton style={{marginBottom:10}} expand="full" size="default" onClick={()=>{adminAction(100)}}>Account Reset</IonButton>
      <IonButton style={{marginBottom:10}} expand="full" size="default" onClick={()=>{adminAction(105)}}>Umfrageantworten löschen</IonButton>
      <IonButton style={{marginBottom:10}} expand="full" size="default" onClick={()=>{adminAction(110)}}>Meine Umfragen löschen</IonButton>
      {
      /*
      <IonButton style={{marginBottom:10}} expand="full" size="default" onClick={()=>{adminAction(115)}}>Meine Vorlagen löschen</IonButton>
      */ 
      }
      <IonButton style={{marginBottom:10}} expand="full" size="default" onClick={()=>{setShowCustomCode(true)}}>Action Code ausführen</IonButton>
      <br/>
      <div style={{fontSize:14,color:"#666", marginTop:20, marginBottom:20, textAlign:"center"}}>Build Version: {state.build}</div>
    </div>
  );
};

export default AdminMenu;
