import { DataStoreDispatchAction, DataStoreType } from "../data/Types";

export const User = {

  logout: (state: DataStoreType, dispatch: React.Dispatch<DataStoreDispatchAction>) => {
    return new Promise((resolve, reject) => {
      state.store.remove("token").then(() => {
        dispatch({type:"reset"});
        resolve("");
      }).catch(() => { reject(); });  
    });
  },

}