import { useRef, useState, useContext, useEffect, useReducer } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { NavContext, IonRouterOutlet, IonPage, IonModal } from '@ionic/react';

import SurveyCreatorStepStart from './survey_creator/StepStart';
import SurveyCreatorStepTemplatePicker from './survey_creator/StepTemplatePicker';
import SurveyCreatorStepOverview from './survey_creator/StepOverview';
import SurveyCreatorStepCustomize from './survey_creator/StepCustomize';
import SurveyCreatorStepElements from './survey_creator/StepElements';
import SurveyCreatorStepUploading from './survey_creator/StepUploading';

import { SurveyCreatorReducer, SurveyCreatorInitialState } from '../data/SurveyCreatorStateManager';
import { Networking } from '../helpers/Networking';
import { CriteriaCustomizingModalPayloadDataInitialState, S3UploadAgreement } from '../data/Types';
import { DataStore } from '../data/DataStore';

import CriteriaEditor from '../components/CriteriaEditor';

/*
  Documentation for the Upload Process:

  Step 1: Request one or more upload URls (presigned S3 urls) via Lambda (/survey/prepare)
  Step 2: Upload the files (photos) to each of those S3 presigned urls directly from here to S3 (via PUT)
  Step 3: Once that's done, replace the filenames in our "draft survey" here with the real filenames S3 has given us in Step 1
  Step 4: Upload the final survey via Lambda to S3 (this also associates it with the user in DynamoDB), via /survey/create
  Step 5: We're done here, let's go back to the main screen.
*/

interface ContainerProps {
  router: HTMLIonRouterOutletElement | null;
}

const SurveyCreatorScreen: React.FC<ContainerProps> = (props) => {

  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => { ref.current = value as any });
    return ref.current;
  }

  const [state, dispatch] = useReducer(SurveyCreatorReducer, SurveyCreatorInitialState);

  const location = useLocation();
  const { navigate } = useContext(NavContext);
  const dataStoreState = useContext(DataStore);

  const previousState = usePrevious(state.creation_state);
  const previousPath = usePrevious(location.pathname);

  const routerRef = useRef(null);

  const [showCriteriaCustomizingModal, setShowCriteriaCustomizingModal] = useState(false);
  const [criteriaCustomizingModalPayload, setCriteriaCustomizingModalPayload] = useState(CriteriaCustomizingModalPayloadDataInitialState);

  const uploadFiles = (destinationData: S3UploadAgreement) => {
    return Promise.all(state.survey.element.map((element, i) => {
      return new Promise((resolve, reject) => {
        let uploadDetails = destinationData.data[i];
        let localFilename = element.picture;
        //let finalFilename = uploadDetails.file_name;
        //console.log(`Kicking off upload [${localFilename}] to [${finalFilename}] [${uploadDetails}]`);
        Networking.uploadFile(localFilename, uploadDetails.url)
          .then((result) => {
            resolve("Done");
          }).catch((error) => {
            reject();
          })
      })
    }))
  }

  useEffect(() => {

    // ALL of this is necessary to catch the stupid back button behaviour.
    // We could remove all of this nonsense if we'd not use the back button,
    // and instead just go with a "Back" button in the content panel.
    // Maybe in the future.

    console.log("Current state: ", state.creation_state);

    // Any state changes that requires us to navigate elsewhere?
    if (typeof (previousState) !== "undefined") {
      if (state.creation_state !== previousState) {

        // This is the "Use & pick template or start from blank" screen 
        if (state.creation_state === 0) {
          // Did we just come from the end of creating a survey? 
          // Then return to the first tab instead.
          console.log("Creator State returned to 0. Coming from: ", previousPath);
          if (previousPath && previousPath === "/create/products") {
            navigate("/surveys?own");
          } else {
            navigate("/create", "root", "replace");
          }
        }

        // Let's start with a template, so show the template picker!
        if (state.creation_state === 50) navigate("/create/pick_template");

        // Regardless of whether a template was picked or not, this screen allows some
        // basic adjustments, like entering a name.
        if (state.creation_state === 100) navigate("/create/overview");

        // This screen shows the template controls if available, or 
        // allows to add/remove more items.
        if (state.creation_state === 200) navigate("/create/customize");

        // This is the screen where all the actual products are being added
        if (state.creation_state === 300) navigate("/create/products");

        // Back to the main screen.
        if (state.creation_state === 1000) {
          console.log("We're done, let's reset the state here and return to another tab.");
          dispatch({ type: "set_state", payload: 0 });
        }
      }
    }
    // Any state changes in the path/route?
    if (previousPath && previousPath !== location.pathname) {
      console.log("Detected a location change from " + previousPath + " to " + location.pathname + ". Perhaps back button?");

      //if (location.pathname === "/surveys") { console.log("[SurveyCreator: Path Processor IGNORED 1] All good."); return; }
      //if ((location.pathname === "/create/customize") && (previousPath === "/surveys")) { console.log("[SurveyCreator: Path Processor IGNORED 2] All good."); return; }

      if (location.pathname === "/create/products" && state.creation_state !== 300) {
        // Did we just land on the last step of the survey creation, but the actual
        // state underneath has changed back to 0? Then redirect!
        navigate("/create");
      }

      if (previousPath === "/create/overview" && location.pathname === "/create") {
        dispatch({ type: "set_state", payload: 0 })
      }
      if (previousPath === "/create/overview" && location.pathname === "/create/pick_template") {
        dispatch({ type: "set_state", payload: 50 })
      }
      if (previousPath === "/create/pick_template" && location.pathname === "/create") {
        dispatch({ type: "set_state", payload: 0 })
      }
      if (previousPath === "/create/customize" && location.pathname === "/create/overview") {
        dispatch({ type: "set_state", payload: 100 })
      }
      if (previousPath === "/create/products" && location.pathname === "/create/customize") {
        dispatch({ type: "set_state", payload: 200 })
      }
    }
  }, [state, navigate, location.pathname, previousState, previousPath, location]);

  return (
    <IonPage>

      <IonRouterOutlet ref={routerRef}>
        <Route exact path="/create">
          <SurveyCreatorStepStart
            onFromTemplate={() => {
              dispatch({ type: "start_from_template" })
            }}
            onFromScratch={() => {
              dispatch({ type: "start_from_scratch" })
            }}
          />
        </Route>
        <Route exact path="/create/pick_template">
          <SurveyCreatorStepTemplatePicker
            onTemplatePicked={(template) => {
              dispatch({ type: "template_picked", payload: template })
            }}
          />
        </Route>
        <Route exact path="/create/overview">
          <SurveyCreatorStepOverview
            survey={state.survey}
            onSurveyUpdate={(surveyData) => {
              dispatch({ type: "overview_editing_completed", payload: surveyData })
            }}
          />
        </Route>
        <Route exact path="/create/customize">
          <SurveyCreatorStepCustomize
            router={routerRef.current}
            survey={state.survey}
            toggleCriteria={(criteria) => {
              dispatch({ type: "toggle_criteria", payload: { criteria: criteria } })
            }}
            onCompleted={() => {
              dispatch({ type: "customization_completed" })
            }}
            onShowCriteriaEditor={(payload) => {
              setCriteriaCustomizingModalPayload(payload);
              setShowCriteriaCustomizingModal(true);
            }}
            removeCriteria={(sectionIndex, criteriaIndex) => {
              dispatch({
                type: "remove_criteria", payload: {
                  sectionIndex: sectionIndex,
                  criteriaIndex: criteriaIndex
                }
              })
            }}
            /*
            addCriteria={(sectionIndex, criteria) => {
              dispatch({type: "add_criteria", payload: { sectionIndex: sectionIndex, criteria: criteria }})
            }}
            replaceCriteria={(sectionIndex, criteriaIndex, criteriaData) => {
              dispatch({type: "replace_criteria", payload: {
                sectionIndex: sectionIndex,
                criteriaIndex: criteriaIndex,
                criteria: criteriaData
              }})              
            }}
            */
            renameSection={(sectionIndex, sectionName) => {
              dispatch({
                type: "rename_section", payload: {
                  sectionIndex: sectionIndex,
                  title: sectionName
                }
              })
            }}
          />

          <IonModal
            animated={true}
            presentingElement={props.router || undefined}
            isOpen={showCriteriaCustomizingModal}
            swipeToClose={true}
            showBackdrop={true}
            onDidDismiss={() => {
              setShowCriteriaCustomizingModal(false)
              // Stupid fix
              try {
                //            let prevPage = document.getElementsByClassName("ion-page can-go-back")[0];
                //          (prevPage as any).style.zIndex = 100;
              } catch (e) { }
            }}
          >
            <CriteriaEditor
              payload={criteriaCustomizingModalPayload}
              sections={state.survey.sections}
              onCancelled={() => { setShowCriteriaCustomizingModal(false) }}
              onCompleted={(sectionIndex, newCriteria) => {
                dispatch({ type: "add_criteria", payload: { sectionIndex: sectionIndex, criteria: newCriteria } })
                setShowCriteriaCustomizingModal(false);
              }}
              onRemoved={() => {
                dispatch({
                  type: "remove_criteria", payload: {
                    sectionIndex: criteriaCustomizingModalPayload.sectionIndex,
                    criteriaIndex: criteriaCustomizingModalPayload.criteriaIndex
                  }
                })
                setShowCriteriaCustomizingModal(false);
              }}
              onChanged={(newCriteriaData) => {
                dispatch({
                  type: "replace_criteria", payload: {
                    sectionIndex: criteriaCustomizingModalPayload.sectionIndex,
                    criteriaIndex: criteriaCustomizingModalPayload.criteriaIndex,
                    criteria: newCriteriaData
                  }
                })
                setShowCriteriaCustomizingModal(false);
              }}
            />
          </IonModal>

        </Route>
        <Route exact path="/create/products">
          {!state.uploading &&
            <SurveyCreatorStepElements
              survey={state.survey}
              onElementAdded={(element) => {
                dispatch({ type: "add_product", payload: element });
              }}
              onElementRemoved={(elementIndex) => {
                dispatch({ type: "remove_element", payload: { elementIndex: elementIndex } });
              }}
              onCompleted={() => {
                dispatch({ type: "uploading" });
                Networking.prepareUpload(state.survey.element.length)
                  .then((destinationData) => {
                    // Now let's upload all the files first...
                    uploadFiles(destinationData as S3UploadAgreement)
                      .then(() => {
                        // Files are uploaded, now we'll need to create the survey
                        // and supply it with the correct filenames, but let's upload
                        // the data first, and then issue the dispatch with the modifications
                        // (new file URLs from S3) later, so if anything goes wrong, we're
                        // still able to restart from the original state.
                        var temporarySurvey = { ...state.survey };
                        temporarySurvey.element = temporarySurvey.element.map((element, i) => {
                          return { ...element, picture: (destinationData as S3UploadAgreement).data[i].file_name }
                        })

                        Networking.createSurvey(dataStoreState.state.user.token, temporarySurvey)
                          .then((success) => {
                            // NOW accept the temporary survey as a permanent survey!
                            dispatch({ type: "upload_completed", payload: temporarySurvey });
                          }).catch((error) => {
                            dispatch({ type: "upload_failed" })
                          })
                      }).catch((error) => {
                        console.log("File upload failed", error);
                        dispatch({ type: "upload_failed" })
                      })
                  }).catch((error) => {
                    // TODO: Show error! NOT IMPLEMENTED YET
                    dispatch({ type: "upload_failed" })
                  })
              }}
            />
          }
          {
            state.uploading &&
            <SurveyCreatorStepUploading
              onCompleted={(success) => {
                console.log("SUCCESS");
              }}
            />
          }
        </Route>

      </IonRouterOutlet>

    </IonPage>
  );
};

export default SurveyCreatorScreen;