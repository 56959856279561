import { IonIcon } from '@ionic/react';
import { ellipse, ellipseOutline} from 'ionicons/icons';

interface ContainerProps {
  stars: number,
  votes?: number
}

const StarRatingResult: React.FC<ContainerProps> = (props) => {
  const iconStyle = {
    fontSize: 26,
    marginRight:5,
    color: "#ddd"
  };
  const iconFilledStyle = {
    fontSize: 26,
    color: "#007bc0",
    marginRight:5
  };
  return (
    <div style={{display:"flex", flexDirection:"row"}}>
      <IonIcon icon={props.stars>=1 ? ellipse : ellipseOutline} style={props.stars>=1 ? iconFilledStyle : iconStyle}/>
      <IonIcon icon={props.stars>=2 ? ellipse : ellipseOutline} style={props.stars>=2 ? iconFilledStyle : iconStyle}/>
      <IonIcon icon={props.stars>=3 ? ellipse : ellipseOutline} style={props.stars>=3 ? iconFilledStyle : iconStyle}/>
      <IonIcon icon={props.stars>=4 ? ellipse : ellipseOutline} style={props.stars>=4 ? iconFilledStyle : iconStyle}/>
      <IonIcon icon={props.stars>=5 ? ellipse : ellipseOutline} style={props.stars>=5 ? iconFilledStyle : iconStyle}/>
      { props.votes!==null && 
        <div style={{fontSize:13, color: "#999", paddingTop:6, paddingLeft: 7}}>
          {props.votes} {props.votes!==1 ? "Stimmen" : "Stimme"}
        </div>
      }
    </div>
  )
};

export default StarRatingResult;
