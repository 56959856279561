import { useContext, useState } from 'react';
import { 
  IonContent, 
  useIonViewWillEnter, 
  IonPage, 
  IonTitle,
  IonHeader, 
  IonToolbar, 
  IonButtons, 
  IonBackButton
} from '@ionic/react';

import { DataStore } from '../../data/DataStore';
import { Networking } from '../../helpers/Networking';

import SurveyTemplateRow from '../../components/SurveyTemplateRow';

import { TemplateType } from '../../data/Types'
import Loading from '../../components/empty_states/Loading';

type ContainerProps = {
  onTemplatePicked: (template: TemplateType) => void
}

const SurveyCreatorStepTemplatePicker: React.FC<ContainerProps> = (props) => {

  const { state, dispatch } = useContext(DataStore);
  const [ loading, setLoading ] = useState(false);

  const refreshData = () => {
    setLoading(true);
    Networking.fetchTemplates(state.user.token)
    .then((templateData: any) => {
      console.log(templateData);
      dispatch({type: "update_templates", payload: templateData});
      setLoading(false);
    }).catch((error) => {
      console.error("Error while trying to refreshData (Templates)", error);
      setLoading(false);
    });
  }

  useIonViewWillEnter(() => {
    refreshData();
  });

  return (
    <IonPage>
      <IonHeader>        
        <IonToolbar style={{backgroundColor: "white"}}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/create/customize"/>
          </IonButtons>
          <IonTitle>Vorlage wählen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{fontSize:16, color: "#333", padding:30, paddingBottom: 0}}>
          HABERKORN
        </div>
        <div style={{paddingLeft:10, paddingRight:10}}>
        { !loading && state.templates.length > 0 && state.templates.map((e,i) => {
            if (e.author_id!=="ad8bf50a1cf87ff6c95f8bdf8f385eca") return (null);
            return <SurveyTemplateRow 
              key={"template_"+i} 
              template={e}
              onClick={()=>{ props.onTemplatePicked(e) }}
            />
          }) 
        }
        </div>
        <div style={{fontSize:16, color: "#333", padding:30, paddingBottom: 0}}>
          Community Vorlagen
        </div>
        <div style={{paddingLeft:10, paddingRight:10}}>
        { !loading && state.templates.length > 0 && state.templates.map((e,i) => {
            if (e.author_id==="ad8bf50a1cf87ff6c95f8bdf8f385eca") return (null);
            return <SurveyTemplateRow 
              key={"template_"+i} 
              template={e}
              onClick={()=>{ props.onTemplatePicked(e) }}
            />
          }) 
        }
        </div>
        {
          loading && <Loading text="Vorlagen werden geladen..."/>
        }
      </IonContent>
    </IonPage>
  );
};

export default SurveyCreatorStepTemplatePicker;
