import React from 'react';
import { alertCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

const FeatureNotAvailable: React.FC<{ onHide: () => void }> = ({ onHide }) => (
  <div style={{padding:30, textAlign:"center"}} onClick={onHide}>
    <IonIcon 
      icon={alertCircleOutline} 
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: 60, 
        color:"#3279B7",
        marginBottom:15
      }}
    /><br/>
    Diese Funktion ist im Moment leider deaktiviert.
  </div>
);

export default FeatureNotAvailable;