import { NavContext, IonContent, IonHeader, IonTitle, IonTextarea, IonPage, IonToolbar, IonButtons, IonBackButton, useIonLoading } from '@ionic/react';
import { useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DataStore } from '../data/DataStore';
import { SurveyResponseItem } from '../data/Types';

import StarRating from '../components/StarRating';
import BooleanRating from '../components/BooleanRating';

import { Networking } from '../helpers/Networking';

import './SurveyForm.css';
import RegularButton from '../components/RegularButton';

interface ContainerProps extends RouteComponentProps<{
  id: string;
}> {}

const SurveyFormScreen: React.FC<ContainerProps> = ({ match }) => {

  // Locate the correct survey
  const {state, dispatch} = useContext(DataStore);
  const { navigate } = useContext(NavContext);

  const [presentLoadingNotice, hideLoadingNotice] = useIonLoading();

  const [surveyResponses, setSurveyResponses] = useState(Array<SurveyResponseItem>());

  let survey = 
    state.shared_surveys.find(x => x.survey_hash === match.params.id) || 
    state.surveys.find(x => x.survey_hash === match.params.id) || 
    null;

  const refreshData = () => {
    return new Promise((resolve, reject) => {
      Networking.getSurveys(state.user.token)
      .then((data) => {
        data.surveys.forEach((survey) => { dispatch({type: "add_or_update_survey", payload: survey}) })
        data.shared_surveys.forEach((survey) => { dispatch({type: "add_or_update_shared_survey", payload: survey}) })
        resolve("");
      }).catch(()=>{ reject(); });  
    });
  }

  const setResponseRating = (sectionIndex: number, elementIndex: number, criteriaIndex: number, rating: number) => {
    var alreadyExisted = false;
    let updatedSurveyResponses = 
      surveyResponses.map(e => {
        if (e.sectionIndex === sectionIndex && e.elementIndex === elementIndex && e.criteriaIndex === criteriaIndex) 
        {
          alreadyExisted = true
          return {...e, rating: rating}
        } else {
          return e
        }
    });
    if (alreadyExisted) {
      setSurveyResponses(updatedSurveyResponses);
    } else {
      // Not existed yet, so let's create it...
      setSurveyResponses([...surveyResponses, {
        sectionIndex: sectionIndex,
        elementIndex: elementIndex,
        criteriaIndex: criteriaIndex,
        rating: rating,
        comment: "",
        type: "stars"
      }]);
    }
  }

  const setResponseComment = (sectionIndex: number, elementIndex: number, criteriaIndex: number, comment: string) => {
    var alreadyExisted = false;
    let updatedSurveyResponses = 
      surveyResponses.map(e => {
        if (e.sectionIndex === sectionIndex && e.elementIndex === elementIndex && e.criteriaIndex === criteriaIndex) 
        {
          alreadyExisted = true
          return {...e, comment: comment}
        } else {
          return e
        }
    });
    if (alreadyExisted) {
      setSurveyResponses(updatedSurveyResponses);
    } else {
      // Not existed yet, so let's create it...
      setSurveyResponses([...surveyResponses, {
        sectionIndex: sectionIndex,
        elementIndex: elementIndex,
        criteriaIndex: criteriaIndex,
        rating: 0,
        comment: comment,
        type: "comment"
      }]);
    }
  }

  const setResponseBoolean = (sectionIndex: number, elementIndex: number, criteriaIndex: number, bool: boolean) => {
    var alreadyExisted = false;
    let updatedSurveyResponses = 
      surveyResponses.map(e => {
        if (e.sectionIndex === sectionIndex && e.elementIndex === elementIndex && e.criteriaIndex === criteriaIndex) 
        {
          alreadyExisted = true
          return {...e, rating: bool ? 1 : 0}
        } else {
          return e
        }
    });
    if (alreadyExisted) {
      setSurveyResponses(updatedSurveyResponses);
    } else {
      // Not existed yet, so let's create it...
      setSurveyResponses([...surveyResponses, {
        sectionIndex: sectionIndex,
        elementIndex: elementIndex,
        criteriaIndex: criteriaIndex,
        rating: bool ? 1 : 0,
        comment: "",
        type: "boolean"
      }]);
    }
  }

  const getResponseRating = (sectionIndex:  number, elementIndex: number, criteriaIndex: number) => {
    let foundResult = surveyResponses.find((e) => ((e.sectionIndex === sectionIndex) && (e.elementIndex === elementIndex) && (e.criteriaIndex === criteriaIndex)))
    return (foundResult && foundResult.rating) || 0;
  }

  const getResponseBoolean = (sectionIndex:  number, elementIndex: number, criteriaIndex: number) => {
    let foundResult = surveyResponses.find((e) => ((e.sectionIndex === sectionIndex) && (e.elementIndex === elementIndex) && (e.criteriaIndex === criteriaIndex)))
    return ( (foundResult && (foundResult.rating===1)) ? true : false) || false;
  }

  const getResponseComment = (sectionIndex:  number, elementIndex: number, criteriaIndex: number) => {
    let foundResult = surveyResponses.find((e) => ((e.sectionIndex === sectionIndex) && (e.elementIndex === elementIndex) && (e.criteriaIndex === criteriaIndex)))
    return (foundResult && foundResult.comment) || "";
  }

  const ensureMissingCriteria = () : Array<SurveyResponseItem> => {
    var finalResponseSheet = surveyResponses;
    survey?.element.forEach((element, elementIndex) => {
      survey?.sections.forEach((section, sectionIndex) => {
        section.criteria.forEach((criteria, criteriaIndex) => {
          // Check if we have a rating for this, if not, we'll need to add a default one!
          var foundRating = false;
          surveyResponses.forEach((response, ri) => {
            if (response.elementIndex === elementIndex && response.sectionIndex === sectionIndex && response.criteriaIndex === criteriaIndex) {
              foundRating = true;
            }
          });
          if (!foundRating) {
            // Nothing found, so let's add a dummy (default) record
            finalResponseSheet.push({
              sectionIndex: sectionIndex,
              elementIndex: elementIndex,
              criteriaIndex: criteriaIndex,
              rating: 0,
              comment: "",
              type: criteria.type || "stars"
            })
          }
        })
      })
    })
    return finalResponseSheet
  }

  const submitRatings = () => {
    if (survey) {
      presentLoadingNotice("Daten werden ausgewertet", 1500);

      // IMPORTANT! Before we can send off the data, we need to ensure that
      // anything the user didn't tap on is also being included!
      // This is kind of messy, but let's hope it'll work the way it's implemented.
      let cleanedResponses = ensureMissingCriteria();
      Networking.sendRatings(state.user.token, survey.survey_hash, cleanedResponses)
        .then((result) => {
          // Now refresh and then go back
          refreshData().then(() => { navigate("/surveys"); hideLoadingNotice(); }).catch(() => { hideLoadingNotice() });
        }).catch((error) => { hideLoadingNotice() })
    }
  }

  if (!survey) {
    setTimeout(() => { refreshData(); }, 500);
    return <IonPage><IonContent><div style={{padding:50}}>Survey not found</div></IonContent></IonPage>;
  }

  return (
    <IonPage>
      <IonHeader>        
        <IonToolbar style={{backgroundColor: "white"}}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/surveys"/>
          </IonButtons>
          <IonTitle>{survey.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{padding:30}}>


        { 
          /*
          <div className="survey-form-title">{survey.name}</div>
          <div className="survey-form-label">{survey.author}</div>
          <div className="survey-title-separator"></div>
          */
        }

          {
            survey?.element.map((surveyElement, elementIndex) => {
              if (typeof(surveyElement)==="undefined") return null;
              return (
                <div key={`survey_element_${elementIndex}`}>
                { elementIndex>0 && <div className="element-separator"></div> }
                <div className="survey-element">
                  <div className="survey-element-title">
                    {surveyElement.name}
                  </div>
                  <div className="survey-element-subtitle">
                    {`Produkt ${elementIndex+1} von ${survey?.element.length}`}
                  </div>
                  <div className="survey-element-img">
                    <img alt="" src={`https://selectifyassets.s3.amazonaws.com/elements/${surveyElement.picture}.jpg`}/>
                  </div>
                  { 
                    survey?.sections.map((surveySection, sectionIndex) => {
                      return (
                        <div className="survey-section" key={`survey_section_${elementIndex}_${sectionIndex}`}>
                          <div className="survey-section-title">{surveySection.title}</div>

                          <div className="survey-element-criteria-container">
                            {surveySection.criteria.map((c, criteriaIndex) => {
                              if (typeof(c)==="undefined") return (null);
                              if (!c.enabled) return (null);
                              return (
                                <div className="survey-criteria-row" key={`criteria_section_${elementIndex}_${sectionIndex}_${criteriaIndex}`}>
                                  {c.name}

                                  {
                                    (c.type === "stars") &&
                                    <StarRating 
                                      key={"rating_iterator_"+sectionIndex+"_"+elementIndex+"_"+criteriaIndex+"_"}
                                      stars={getResponseRating(sectionIndex, elementIndex, criteriaIndex)}
                                      onSet={(stars) => { setResponseRating(sectionIndex, elementIndex, criteriaIndex, stars) }}
                                    />
                                  }

                                  {
                                    (c.type === "boolean") &&
                                    <BooleanRating 
                                      key={"rating_iterator_"+sectionIndex+"_"+elementIndex+"_"+criteriaIndex+"_"}
                                      state={getResponseBoolean(sectionIndex, elementIndex, criteriaIndex)}
                                      onSet={(state) => { setResponseBoolean(sectionIndex, elementIndex, criteriaIndex, state) }}
                                    />
                                  }

                                  {
                                    (c.type === "comment") &&
                                    <div className="survey-element-comment">
                                      <IonTextarea 
                                        debounce={400}
                                        placeholder="Kommentar (optional)"
                                        value={getResponseComment(sectionIndex, elementIndex, criteriaIndex)} 
                                        onIonChange={e => setResponseComment(sectionIndex, elementIndex, criteriaIndex, e.detail.value || "")}
                                      ></IonTextarea>
                                    </div>
                                  }

                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                  })
                }
                </div>
                </div>
              )               
            })
          }
          
          <div style={{margin:0}}>
            <RegularButton
              title="Bewertung abschicken"
              onClick={submitRatings}
            />
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default SurveyFormScreen;
