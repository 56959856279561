import { useState } from 'react';
import { 
  IonButton,
  IonButtons, 
  IonHeader, 
  IonToolbar, 
  IonPage,
  IonContent,
  IonTitle,
  IonInput,
  IonCard,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonSelect, 
  IonSelectOption,
  useIonViewDidEnter,
  useIonViewWillEnter
} from '@ionic/react';
import { CriteriaCustomizingModalPayloadData, SurveyCriteriaType, SurveySectionType } from '../data/Types';
import RegularButton from './RegularButton';
import { v4 as uuidv4 } from 'uuid';

interface ContainerProps {
  payload: CriteriaCustomizingModalPayloadData
  onCancelled: () => void
  onRemoved: () => void
  onChanged: (newCriteriaData: SurveyCriteriaType) => void
  onCompleted: (sectionIndex: number, newCriteria: SurveyCriteriaType) => void
  sections: SurveySectionType[]
}

const CriteriaEditor: React.FC<ContainerProps> = (props) => {

  const [newCriteriaTitle, setCriteriaTitle] = useState("");
  const [newCriteriaType, setCriteriaType] = useState("stars");
  const [newCriteriaSectionIndex, setCriteriaSectionIndex] = useState(0);
  const [editorTitle, setEditorTitle] = useState("Kriterium hinzufügen");

  useIonViewDidEnter(() => {
    
  })

  useIonViewWillEnter(() => {
    if (props.payload.mode === "edit") {
      setEditorTitle("Kriterium editieren");
      let sectionIndex = props.payload.sectionIndex || 0;
      let criteriaIndex = props.payload.criteriaIndex || 0;
      let sectionDetails = props.sections[sectionIndex];
      if (sectionDetails) {
        let criteriaDetails = sectionDetails.criteria[criteriaIndex];
        if (criteriaDetails && criteriaDetails.type && criteriaDetails.name) {
          setCriteriaTitle(criteriaDetails.name);
          setCriteriaType(criteriaDetails.type);
          setCriteriaSectionIndex(sectionIndex);
        }        
      }
    } else {
      setEditorTitle("Kriterium hinzufügen");
    }
  })

  return (
    <IonPage>
      <IonHeader>        
      <IonToolbar style={{backgroundColor: "white"}}>
          <IonTitle>{editorTitle}</IonTitle>
          <IonButtons slot="end">
          <IonButton expand="block" onClick={()=>props.onCancelled()}>
            Abbrechen
          </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{padding:20}}>
        {/*
        <IonCard>
          <IonCardContent>
        */ }
            {props.sections && props.sections.length > 1 && (props.payload.mode === "create") &&
            <>
            <IonLabel>Abschnitt</IonLabel>
            <div style={{height:10}}></div>
            <IonSelect 
              className="section-selector"
              interface="action-sheet"
              value={newCriteriaSectionIndex} 
              okText="Übernehmen" 
              cancelText="Abbrechen" 
              onIonChange={e => setCriteriaSectionIndex(e.detail.value)}
            >
              {
                props.sections && props.sections.map((s,i) => {
                  return <IonSelectOption key={`section_${i}`} value={i}>{s.title}</IonSelectOption>                  
                })
              }
            </IonSelect>
            <div style={{height:20}}></div>
            </>
            }

            <IonLabel style={{paddingBottom:20}}>Name des Kriteriums</IonLabel>
            <div style={{height:10}}></div>
            <IonInput 
              type="text"
              className="criteria-title-input"
              value={newCriteriaTitle} 
              autofocus={false}
              placeholder="Bezeichnung" 
              onIonChange={e => { setCriteriaTitle(e.detail.value!); } }></IonInput>

            <div style={{height:20}}></div>

            <IonLabel>Art der Bewertung</IonLabel>
            <div style={{height:10}}></div>
            <IonSegment
              value={newCriteriaType}
              onIonChange={e => setCriteriaType(e.detail.value || "stars")}>
              <IonSegmentButton value="stars">
                <IonLabel>5-Sterne</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="boolean">
                <IonLabel>Ja/Nein</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="comment">
                <IonLabel>Kommentar</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          
            <div style={{marginTop: 20}}>
              <RegularButton
                title="Übernehmen"
                onClick={() => {
                  let newCriteria: SurveyCriteriaType = {
                    name: newCriteriaTitle,
                    type: newCriteriaType,
                    enabled: true,
                    id: uuidv4(),
                  }

                  // Was this a new creation, or an edit?
                  if (props.payload.mode === "edit") {
                    props.onChanged(newCriteria);
                  } else {
                    props.onCompleted(newCriteriaSectionIndex, newCriteria)
                  }
                }}
              />                    
            </div>

            <div style={{marginTop: 20}}>
              <RegularButton
                danger={true}
                title="Entfernen"
                onClick={() => {
                  props.onRemoved();
                }}
              />                    
            </div>            

          { /*
          </IonCardContent>
        </IonCard>
              */ }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CriteriaEditor;