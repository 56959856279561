import React from 'react';
import {
  SurveyCreatorStateType,
  SurveyCreatorDispatchAction,
  TemplateType,
  SurveyWithSectionsTypeInitialState,
  SurveyWithSectionsType,
  SurveyCriteriaType,
  SurveyElementType
} from './Types';

export const SurveyCreatorInitialState = { survey: SurveyWithSectionsTypeInitialState, creation_state: 0, uploading: false };

export const SurveyCreatorReducer = (state: SurveyCreatorStateType, action: SurveyCreatorDispatchAction) => {
  
  console.log("SurveyCreatorReducer Action:", action.type);
  console.log("...with payload:", action.payload);

  switch (action.type) {    
    case "set_state":
      return {...state, creation_state: action.payload as number}
    case "start_from_scratch":
      // We'll add a default section to the initial state as creating sections won't be
      // available right away in the app. 
      return {
        ...state,
        survey: {
          ...SurveyWithSectionsTypeInitialState,
          sections: [
            {
              title: "",
              criteria: [],
              enabled: true
            }
          ],          
        },
        creation_state: 100
      }
    case "start_from_template":
      return {
        ...state,
        creation_state: 50,
      }
    case "template_picked":
      let pickedTemplate = action.payload as TemplateType;
      let updatedSurvey = SurveyWithSectionsTypeInitialState;
      updatedSurvey.name = pickedTemplate.template_name;
      updatedSurvey.sections = pickedTemplate.sections.map((section,i) => {
        return {
          ...section,
          enabled: true, 
          criteria: section.criteria.map(criteria => { return {...criteria, enabled: true} })
        }
      });    
      return {...state, survey: updatedSurvey, creation_state: 100}
    case "toggle_criteria":
      let sections = state.survey.sections.map((section,i) => {
        return {
          ...section,
          criteria: section.criteria.map(criteria => { 
            return {
              ...criteria, 
              enabled: (action.payload.criteria as SurveyCriteriaType) === criteria ? !criteria.enabled : criteria.enabled
            }
          })
        }
      });
      return {...state, survey: {...state.survey, sections: sections} }
    case "remove_criteria":
      return {...state, survey: {
        ...state.survey,
        sections: state.survey.sections.map((section, sectionIndex) => {
          if (sectionIndex === action.payload.sectionIndex) {
            return {
                ...section,
                criteria: section.criteria.filter((criteria, criteriaIndex) => {
                  return (criteriaIndex===action.payload.criteriaIndex) ? false : criteria
                })
            }
          } else {
            return section
          }
        })
      }}
    case "replace_criteria":
      return {...state, survey: {
        ...state.survey,
        sections: state.survey.sections.map((section, sectionIndex) => {
          if (sectionIndex === action.payload.sectionIndex) {
            return {
                ...section,
                criteria: section.criteria.map((criteria, criteriaIndex) => {
                  return (criteriaIndex === action.payload.criteriaIndex) ? action.payload.criteria : criteria
                })
            }
          } else {
            return section
          }
        })
      }}      
    case "rename_section":
      return {...state, survey: {...state.survey, sections: 
        state.survey.sections.map((section, sectionIndex) => {
          if (sectionIndex === action.payload.sectionIndex) {
            return {...section, title: action.payload.title}
          } else {
            return section;
          }
        })      
      }}      
    case "remove_element":
      return {...state, survey: {
        ...state.survey,
        element: state.survey.element.filter((element, elementIndex) => {
          console.log(`Filtering? ${elementIndex} == ${action.payload.elementIndex}`)
          return (elementIndex !== action.payload.elementIndex)
        })
      }}   
    case "overview_editing_completed":
      return {...state, survey: action.payload as SurveyWithSectionsType, creation_state: 200}
    case "customization_completed":
      return {...state, creation_state: 300}
    case "add_criteria":
      var sectionsToBeEdited = state.survey.sections;
      sectionsToBeEdited[action.payload.sectionIndex].criteria.push(action.payload.criteria as SurveyCriteriaType)
      return {...state, sections: sectionsToBeEdited}
    case "add_product":
      return {
        ...state, 
        survey: {...state.survey, element: [...state.survey.element, (action.payload as SurveyElementType)]}
      }
    case "uploading":
      return {...state, uploading: true}
    case "upload_completed":
      return {...state, uploading: false, survey: action.payload as SurveyWithSectionsType, creation_state: 1000}
    case "upload_failed":
      // TODO, include error here to show it...
      return {...state, uploading: false}
    default:
      return state
  }
}

export const SurveyCreatorStateManager = React.createContext<{
  state: SurveyCreatorStateType,
  dispatch: React.Dispatch<SurveyCreatorDispatchAction>
  } > ({
    state: SurveyCreatorInitialState,
    dispatch: () => undefined,
});