import React from 'react';
import { IonCard, IonCardContent, IonSkeletonText } from '@ionic/react';

const LoadingPlaceholder: React.FC = () => {
  return (
    <IonCard style={{margin:25}}>
      <IonCardContent>
        <IonSkeletonText animated={true} style={{height: 25, width: "50%"}}/>
        <IonSkeletonText animated={true} style={{height: 25, width: "100%"}}/>
        <IonSkeletonText animated={true} style={{height: 40, width: "100%"}}/>
      </IonCardContent>
    </IonCard>
  );
};

export default LoadingPlaceholder;