import { useContext, useEffect, useState } from 'react';
import { NavContext, IonButton, IonContent, IonPage, IonIcon } from '@ionic/react';
import { logOutOutline} from 'ionicons/icons';
import MainToolbar from '../components/MainToolbar';
import { DataStore } from '../data/DataStore';
import { User } from '../helpers/User';

import './Profile.css';
import AdminMenu from './AdminMenu';
import RegularButton from '../components/RegularButton';

const ProfileScreen: React.FC = () => {

  const {state, dispatch} = useContext(DataStore);
  const {navigate} = useContext(NavContext);
  const [adminTaps, setAdminTaps] = useState(0);

  const adminTap = () => {
    setAdminTaps(adminTaps+1);
  }

  useEffect(() => {
    if (adminTaps >= 6) {
      setAdminTaps(0);
    }
  }, [adminTaps])

  return (
    <IonPage>      
      <MainToolbar onClick={adminTap}/>
      <IonContent fullscreen>

        {
          adminTaps<3 &&
          <div style={{padding: 30}}>
            <h1>Profil</h1>
            <span style={{color:"#666", fontSize:16}}>Dieses Feature ist in der aktuellen Version deaktiviert.</span>
          </div>
        }

        {
          adminTaps>=3 && <AdminMenu/>
        }

        <div style={{textAlign:"center", marginTop:0, padding: 30}}>        

          <RegularButton
            title="Abmelden"
            onClick={()=>{ 
              User.logout(state, dispatch).then(() => { navigate("/"); })
            }}
          />

          <br/><br/><br/>
          
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ProfileScreen;
