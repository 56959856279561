import { useContext, useState } from 'react';
import { 
  IonContent, 
  IonPage,
  IonItem, 
  IonList, 
  IonImg, 
  IonInput, 
  IonButton,
  useIonLoading
} from '@ionic/react';

import { DataStore } from '../data/DataStore';
import { Networking } from '../helpers/Networking';
import RegularButton from '../components/RegularButton';

const LoginScreen: React.FC = () => {

  const { state, dispatch } = useContext(DataStore);

  const [presentLoadingNotice, hideLoadingNotice] = useIonLoading();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);

  const attemptLogin = () => {
    if ((username.length === 0) || (password.length === 0)) {
      setLoginError(true);
      return;
    }

    presentLoadingNotice("Anmeldung verifizieren", 1500);

    Networking.userLogin(username, password)
    .then((userData: any) => {
      if (userData.token && userData.token.length > 0) {
        state.store.set("token", userData.token).then(() => {
          dispatch({type: "mark_user_authenticated", payload: {
            token: userData.token,
            loggedIn: true,
            isEditor: true,
          }})
        });
      } else {
        console.log("NOT LOGGING IN: No Access Token received");
        dispatch({type: "mark_user_deauthenticated"})
        setLoginError(true);
      }
      hideLoadingNotice();
    }).catch((error) => {
      console.log("NOT LOGGING IN: Error while trying to authenticate", error);
      dispatch({type: "mark_user_deauthenticated"})
      setLoginError(true);
      hideLoadingNotice();
    });
  }

  return (
      <IonPage>
        <IonContent>
        <IonImg src={"assets/hselect-logo.png"} style={{height:20, marginTop:65, marginLeft:"auto", marginRight:"auto", marginBottom:10}}/>
        <div style={{padding:30, paddingTop:0}}>
          <IonList lines="none">
            <IonItem lines="none">
              <div style={{marginTop:30, width:"100%", marginBottom:20, textAlign: "center"}}>
                Melden Sie sich mit Ihrem Konto an
              </div>
            </IonItem>
            <IonItem lines="none">
              <IonInput
                type="email"
                className="login-field"
                value={username} 
                autofocus={true}
                autocomplete={"username"}
                placeholder="E-mail" 
                onIonChange={e => { setUsername(e.detail.value!.toLocaleLowerCase()); setLoginError(false); } }></IonInput>
            </IonItem>
            <div style={{height:20}}></div>
            <IonItem lines="none">
              <IonInput 
                type="password"
                className="login-field"
                value={password} 
                autofocus={false}
                autocomplete="current-password"
                placeholder="Passwort" 
                onIonChange={e => { setPassword(e.detail.value!); setLoginError(false); } }></IonInput>
            </IonItem>

            <br/>
            { !!loginError &&
            <div style={{color:"red", textAlign: "center", fontSize: 16, padding: 15, marginBottom:15}}>
              Die Anmeldung war leider nicht erfolgreich. Bitte die Daten noch einmal kontrollieren.
            </div>
            }
            { /*
            <br/>
            <div style={{textAlign:"center", color:"#666"}}>Passwort vergessen?</div>
            <br/><br/>
            */ 
            }

            <div style={{margin:10}}>
              <RegularButton
                title="Anmelden"
                onClick={attemptLogin}
              />
            </div>

          </IonList>
        </div>
          
        </IonContent>
      </IonPage>
  );
};

export default LoginScreen;
