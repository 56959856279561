import { IonContent, IonPage, IonCard, IonCardContent, IonIcon } from '@ionic/react';
import { documentOutline, createOutline} from 'ionicons/icons';
import { CSSProperties } from 'react';
import MainToolbar from '../../components/MainToolbar';

type ContainerProps = {
  onFromTemplate: () => void
  onFromScratch: () => void
}

const SurveyCreatorStepStart: React.FC<ContainerProps> = (props) => {
  const cardStyle:CSSProperties = {
    padding: 40,
    textAlign: "center",
  }
  const iconStyle:CSSProperties = {
    fontSize: 60,
    color: "#007bc0",
    marginBottom: 10,
  }
  const titleStyle:CSSProperties = {
    fontWeight: "bold",
    color: "#000",
    marginBottom:10
  }
  const subtitleStyle:CSSProperties = {
    fontSize: 13,
    color: "#666"
  }

  return (
    <IonPage style={{padding:10, paddingTop: 0}}>
      <MainToolbar/>
      <IonContent>
        <IonCard onClick={()=>{ props.onFromTemplate() }}>
          <IonCardContent style={{...cardStyle}}>
            <div><IonIcon icon={documentOutline} style={{...iconStyle}}/></div>
            <div style={{...titleStyle}}>Mit Vorlage starten</div>
            <div style={{...subtitleStyle}}>Wählen Sie aus sorgfältig erstellten Vorlagen unserer Sortimentsexperten</div>
          </IonCardContent>
        </IonCard>

        <IonCard onClick={()=>{ props.onFromScratch() }}>
          <IonCardContent style={{...cardStyle}}>
            <div><IonIcon icon={createOutline} style={{...iconStyle}}/></div>
            <div style={{...titleStyle}}>Leere Umfrage</div>
            <div style={{...subtitleStyle}}>Erstellen Sie eine Umfrage<br/>aus eigenen Kriterien</div>
          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  );
};

export default SurveyCreatorStepStart;
