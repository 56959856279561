import { IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import { checkmarkCircleOutline, arrowDownSharp } from 'ionicons/icons';

interface ContainerProps {
  showInfo?: boolean
}

const NoSurveys: React.FC<ContainerProps> = (props) => {
  return (
    <IonGrid style={{marginTop:0}}>
      <IonRow>
        <IonCol size="12">
          <div style={{width: "100%", textAlign:"center"}}>
            <div style={{marginTop:15, color: "#666"}}>
              Derzeit sind keine Umfragen vorhanden.
            </div>
            {props.showInfo && 
            <>
              <div style={{marginTop:100, color: "#bbb", fontSize: 14}}>
                Ziehen um Umfragen<br/>zu aktualisieren
              </div>
              <div style={{textAlign:"center", marginTop:10}}>
                <IonIcon icon={arrowDownSharp} style={{color:"#ddd"}}/>
              </div>
            </>
            }
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default NoSurveys;