import { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { IonContent, IonPage, IonHeader, IonButtons, IonButton, IonBackButton, IonToolbar } from '@ionic/react';
import { DataStore } from '../data/DataStore';

import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share';
import { Filesystem, Directory } from '@capacitor/filesystem';

import QRCode from 'qrcode';

import "./ShareSurvey.css";
import RegularButton from '../components/RegularButton';

interface ContainerProps extends RouteComponentProps<{
  id: string;
}> {}

const ShareSurveyScreen: React.FC<ContainerProps> = ({ match }) => {

  // Locate the correct survey
  const { state } = useContext(DataStore);
  const [qrCodeImage, setQrCodeImage] = useState("");

  let survey = state.surveys.find(x => x.survey_hash === match.params.id) || null;

  const shareSurvey = async () => {
    await Share.share({
      title: 'Zugangscode für die Umfrage',
      text: `In der Selectify App einfach diesen Code eingeben um an der Umfrage teilzunehmen: ${survey?.short_hash} \n\n`,
      url: `https://selectify.twentypeople.com/deeplink/?code=${survey?.short_hash}`,
      dialogTitle: 'Umfrage teilen',
    }).catch(()=>{});
  }

  const shareSurveyWithQR = () => {
    Filesystem.writeFile({
      path: "qrcode.jpg",
      data: qrCodeImage,
      directory: Directory.Documents,
    }).then((result) => {
      Share.share({
        title: 'Zugang für die Umfrage',
        text: ``,
        url: result.uri,
        dialogTitle: 'Umfrage teilen',
      }).catch(()=>{});
    }
    );
  }  

  const generateQrCode = () => {
    QRCode.toDataURL(`https://selectify.twentypeople.com/deeplink/?code=${survey?.short_hash}`, { errorCorrectionLevel: 'Q' }, (err, url) => {
      setQrCodeImage(url);
    })
  }

  useEffect(()=>{
    if (typeof(qrCodeImage)==="undefined" || qrCodeImage === "") 
      if (survey?.short_hash) {
        generateQrCode();
      }
  },[survey, qrCodeImage])

  return (
    <IonPage>
      <IonHeader>        
        <IonToolbar style={{backgroundColor: "white"}}>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/surveys"/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div style={{textAlign:"center", marginTop:10, padding: 30}}>
          
          {
            qrCodeImage!=="" && 
            <>
            { 
              Capacitor.isNativePlatform() && 
              <div style={{fontSize:11, color:"#aaa", marginBottom:0, paddingBottom:0, position:"relative", top:10}}>Speichern oder teilen mit Tap</div>
            }
            <img src={qrCodeImage} onClick={shareSurveyWithQR} alt="qrcode" style={{width:180, height:180}}/>
            </>
          }
          
          <div style={{fontSize:20, marginTop: 20}}>
          Sie können die Umfrage auch<br/> mit folgendem Shortcode weitergeben:
          </div>          
          <div className="short-code-block">{survey?.short_hash}</div>          
          Andere Nutzer geben den Code ein und<br/>können damit direkt an der Umfrage teilnehmen.
          
          <br/><br/><br/>

          <RegularButton
            title="Umfrage teilen"
            onClick={shareSurvey}
          />

        </div>
      </IonContent>
    </IonPage>
  );
};

export default ShareSurveyScreen;
