import { CSSProperties } from "react";

interface ContainerProps {
  onClick: () => void,
  title: string,
  secondary?: boolean,
  danger?: boolean,
  disabled?: boolean,
}

const RegularButton: React.FC<ContainerProps> = (props) => {

  var buttonOuterStyle: CSSProperties = {      
    width: "100%",
    boxSizing:"border-box",  
    margin:0,
    borderRadius:5,
    padding:12, 
    height: 44,        
    backgroundImage: props.secondary ? 'none' : 'linear-gradient(to right, #006DAB, #50A9E1)',
    borderColor: props.secondary ? '#006dab' : '#ffffff',
    borderStyle: props.secondary ? 'solid' : 'none',
    borderWidth: props.secondary ? 1 : 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: props.disabled ? 0.3 : 1
  };

  var buttonInnerStyle: CSSProperties = {
    color: props.secondary ? '#006dab' : '#ffffff',
    fontSize: 16, 
    fontWeight: props.secondary ? "normal" : "bold", 
    textAlign: "center"
  };

  if (props.danger) {
    buttonOuterStyle = {...buttonOuterStyle, borderWidth: 1, borderStyle: "solid", borderColor: "#aa0000", backgroundImage: 'none' }
    buttonInnerStyle = {...buttonInnerStyle, fontWeight: "normal", color: "#aa0000" }
  }

  return (
    <div 
      onClick={props.disabled ? () => {} : props.onClick}
      style={{...buttonOuterStyle}}>
      <div>
        <div style={{...buttonInnerStyle}}>{props.title}</div>
      </div>
    </div>
  );
};

export default RegularButton;