import React, { useReducer, useEffect, useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  isPlatform,
  useIonViewDidEnter
} from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';
import { clipboardOutline, personOutline, addCircleOutline } from 'ionicons/icons';

import SurveyScreen from './pages/Surveys';
import ResultsScreen from './pages/Results';
import GroupsScreen from './pages/Groups';
import ProfileScreen from './pages/Profile';
import LoginScreen from './pages/Login';
import SurveyCreatorScreen from './pages/SurveyCreator';

import { DataStore, DataStoreInitialState, DataStoreReducer } from './data/DataStore';

import { App as CapacitorApp, AppState } from '@capacitor/app';
import { codePush, InstallMode } from 'capacitor-codepush';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css';
import { Networking } from './helpers/Networking';

const App: React.FC = () => {

  const [state, dispatch] = useReducer(DataStoreReducer, DataStoreInitialState);
  const appRouterRef = useRef <HTMLIonRouterOutletElement | null> (null);

  CapacitorApp.addListener('appUrlOpen', data => {
    //console.log('App opened with URL:', data);
    if (data.url.indexOf("deeplink/?code=")>=0) {
      // We have a short-code open, let's see if we can pull the data...
      // Only if the user is logged in...
      if (state.initialized && state.user.token) {
        let shortHash = data.url.split("deeplink/?code=")[1];
        Networking.associateByShortHash(state.user.token, shortHash)
        .then(() => {
          // Let's refresh the shared surveys now...
          Networking.getSurveys(state.user.token)
          .then((data) => {
            data.shared_surveys.forEach((survey) => { dispatch({type: "add_or_update_shared_survey", payload: survey}) })
          }).catch(()=>{ })
        })
      }
    }
  });

  CapacitorApp.addListener('appStateChange', (state: AppState) => {
    // state.isActive contains the active state
    if (state.isActive) {
      if (isPlatform("ios") || isPlatform("android")) {
        if (!isPlatform("mobileweb")) {
          checkForUpdate();  
        }
      }  
    }
  });  
  
  useEffect(() => {
    if (!state.initialized) {
      state.store.create().then(() => {
        dispatch({type: "storage_initialized"});
        state.store.get("token").then((token) => {
          if (token !== null && token !== "") {
            dispatch({type: "mark_user_authenticated", payload: {
              token: token,
              loggedIn: true,
              isEditor: true,
            }});            
          }
        })
      })
    } 
  },[]);

  const checkForUpdate = () => {
    codePush.sync({ 
      updateDialog: {
        mandatoryUpdateMessage: "Es ist ein notwendiges Update verfügbar. Die App wird in Kürze neu gestartet.",
        mandatoryContinueButtonLabel: "Weiter",
        updateTitle: "Update verfügbar"
      }, 
      installMode: InstallMode.IMMEDIATE 
    });
  }

  useIonViewDidEnter(() => {
    codePush.notifyApplicationReady();
  })

  return (
    <DataStore.Provider value={{state, dispatch}}>
      <IonApp>
        <IonReactRouter>
          {(!state.user.loggedIn ? 
            <IonRouterOutlet>
              <Route path="/">
                <LoginScreen/>
              </Route>
            </IonRouterOutlet>
            :
            <IonTabs>

              <IonRouterOutlet ref={appRouterRef}>
                <Route path="/create" exact={false}><SurveyCreatorScreen router={appRouterRef.current}/></Route>
                <Route path="/surveys" exact={false} component={SurveyScreen}/>
                <Route path="/results" exact={false} component={ResultsScreen}/>
                <Route path="/groups" exact={true} component={GroupsScreen}/>
                <Route path="/profile" exact={true} component={ProfileScreen}/>
                <Redirect exact from="/" to="/surveys" />
              </IonRouterOutlet>

              <IonTabBar slot="bottom">
                <IonTabButton tab="surveys" href="/surveys">
                  <IonIcon icon={clipboardOutline} />
                  <IonLabel>Umfragen</IonLabel>
                </IonTabButton>

                <IonTabButton tab="surveycreator" href="/create">
                  <IonIcon icon={addCircleOutline} />
                  <IonLabel>Neue Umfrage</IonLabel>
                </IonTabButton>

                { 
                /*                
                <IonTabButton tab="results" href="/results">
                  <IonIcon icon={statsChart} />
                  <IonLabel>Ergebnisse</IonLabel>
                </IonTabButton>
                <IonTabButton tab="groups" href="/groups">
                  <IonIcon icon={peopleCircleOutline} />
                  <IonLabel>Gruppen</IonLabel>
                </IonTabButton>
                                  */ 
              }

                <IonTabButton tab="profile" href="/profile">
                  <IonIcon icon={personOutline} />
                  <IonLabel>Profil</IonLabel>
                </IonTabButton>
              </IonTabBar>

            </IonTabs>
          )}
        </IonReactRouter>
      </IonApp>
    </DataStore.Provider>
  )
};

export default App;
