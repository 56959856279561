import { useEffect, useState, useContext, useRef } from 'react';
import { 
  IonContent, 
  IonInput, 
  IonIcon, 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonButtons, 
  IonButton, 
  IonBackButton, 
  IonSegmentButton, 
  IonSegment, 
  IonTitle, 
  isPlatform,
  useIonToast,
  useIonViewDidLeave,
  useIonViewWillEnter,
  NavContext
} from '@ionic/react';
import { lockOpenOutline, lockClosedOutline } from 'ionicons/icons';
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { Networking } from '../helpers/Networking';
import { DataStore } from '../data/DataStore';
import Loading from '../components/empty_states/Loading';
import RegularButton from '../components/RegularButton';
import jsQR from 'jsqr';
import PWAQR from '../components/PWAQR';

const AddSurveyScreen: React.FC = () => {

  const { state, dispatch } = useContext(DataStore);
  const [mode, setMode] = useState("code");
  const [code, setCode] = useState("");
  const [successfullyAdded, setSuccess] = useState(false);
  const [present, dismiss] = useIonToast();
  const [addedSurveyHash, setAddedSurveyHash] = useState("");
  const [loading, setLoading] = useState(false);

  const [showScanner, setShowScanner] = useState(false);

  const { navigate } = useContext(NavContext);

  const videoRef = useRef<HTMLVideoElement>();

  const processQRCode = (code: string) => {
    if (code && code.indexOf("?code=") >= 0) {
      let scannedCode = code.split("code=")[1]; 
      setShowScanner(false);     
      associateSurvey(scannedCode);
    }
  };

  const associateSurvey = (shortCode: string) => {
    if (shortCode.length === 0) return;
    setLoading(true);
    Networking.associateByShortHash(state.user.token, shortCode)
    .then((surveyHash) => {
      // Let's refresh the shared surveys now...
      Networking.getSurveys(state.user.token)
      .then((data) => {
        data.shared_surveys.forEach((survey) => { dispatch({type: "add_or_update_shared_survey", payload: survey}) })
        setAddedSurveyHash(surveyHash);
        //setSuccess(true);
        setLoading(false);
        navigate("/surveys");
      }).catch(()=>{ setLoading(false); })
    }).catch(() => {
      setLoading(false);
      present({
        duration: 3000,
        message: 'Die Umfrage wurde leider nicht gefunden.',
        color: "danger",
        position: "top"
      });
    })
  }

  useEffect(() => {

    if (isPlatform("ios") || isPlatform("android")) {
      if (isPlatform("mobileweb")) {
        if (mode === "qr") {
          setShowScanner(true);          
        } else {
          setShowScanner(false);
        }
      } else {
        if (mode === "qr") {
          BarcodeScanner.checkPermission({ force: true })
          .then((permission) => {
            BarcodeScanner.hideBackground()
            document.body.classList.add("qr-scanner");
            BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] }).then((result) => {
              if (result.hasContent) {
                //console.log("QR CONTENT FOUND", result);
                if (result.content && result.content.indexOf("?code=")>=0) {
                  let scannedCode = result.content.split("code=")[1];
                  BarcodeScanner.stopScan();
                  document.body.classList.remove("qr-scanner");
                  BarcodeScanner.showBackground();            
                  associateSurvey(scannedCode);
                }
              }
            })  
          })
        } else {
          document.body.classList.remove("qr-scanner");
          BarcodeScanner.stopScan();
          BarcodeScanner.showBackground();    
        }  
      }
    }
  }, [mode]);

  useIonViewDidLeave(() => {
    if (!isPlatform("mobileweb")) {
      console.log("View closing, time to close the camera");
      document.body.classList.remove("qr-scanner");
      BarcodeScanner.stopScan();
      BarcodeScanner.showBackground();
    }
  });

  useIonViewWillEnter(() => {
    setSuccess(false);
    setLoading(false);
    setCode("");
    setMode("code");
    setAddedSurveyHash("");
  })

  return (
    <IonPage>
      <IonHeader>        
        <IonToolbar style={{backgroundColor: "white"}}>
          <IonButtons slot="start">            
            <IonBackButton defaultHref="/create"/>
          </IonButtons>
          <IonTitle>Umfrage hinzufügen</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSegment value={mode} onIonChange={(e) => { setMode(e.detail.value || "") }}>
            <IonSegmentButton value={"code"}>Code Eingabe</IonSegmentButton>
            <IonSegmentButton value={"qr"}>QR Scanner</IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>    

      {
        loading && <div style={{marginTop:110}}><Loading text="Umfrage wird gesucht..."/></div>
      }

      {
        successfullyAdded &&
        <div style={{width:300, textAlign: "center", marginLeft:"auto", marginRight: "auto", marginTop:80}}>
          <IonIcon icon={lockOpenOutline} style={{fontSize:60, marginBottom: 40}}/><br/>
          Umfrage wurde hinzugefügt!<br/><br/>
          <RegularButton
            title="Jetzt öffnen"
            onClick={() => { navigate(`/surveys`) }}
          />
        </div>
      }

      { !loading && !successfullyAdded && mode === "code" &&
        <div style={{textAlign:"center", marginTop:100}}>
          <IonIcon icon={code.length===0 ? lockClosedOutline : lockOpenOutline} style={{fontSize:60, marginBottom: 40}}/>

          <br/>
          Umfrage-Code eingeben<br/>
          {
            <IonInput 
              style={{fontSize:30, color: "#007bc0", boxShadow:"0px 0px 15px #ddd", borderRadius:10, maxWidth:150, marginLeft:"auto", marginRight:"auto", marginTop:30, marginBottom:30}}
              placeholder="- - - -" 
              value={code} 
              onKeyDown={(e) => { if (e.key === "Enter") { associateSurvey(code) } }}
              onIonChange={(e) => { setCode(e.detail.value || "") }}
            />
          }
          <br/>
          <div style={{width:300, marginLeft:"auto", marginRight: "auto"}}>

            <RegularButton
              title="Umfrage öffnen"
              onClick={() => { associateSurvey(code) }}
              disabled={code.length===0}
            />
          </div>
        </div>
      }

      { !loading && !successfullyAdded && mode === "qr" &&
        <>
          <PWAQR onSuccess={(code:string)=>{processQRCode(code)}}/><br/>
          <div style={{padding:20, paddingTop:0, textAlign:"center"}}>
  
            Die Umfrage wird automatisch hinzugefügt, sobald ein gültiger QR Code erkannt wird.
            <br/><br/>

            <RegularButton
                title="Scan abbrechen"
                onClick={() => { navigate(`/surveys`) }}
            />
          </div>
        </>
      }        
      </IonContent>
    </IonPage>
  );
};

export default AddSurveyScreen;
