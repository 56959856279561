import axios from 'axios';
import { SurveyResultsData, SurveyResponseItem, SurveyWithSectionsType, SurveyRequest, TemplateType } from '../data/Types';

export const Networking = {

  //getServer: () => {
  //  if (isPlatform("ios")) return "https://selectify-backend.twentypeople.com";
  //  return process.env.REACT_APP_API_SERVER;
  //},

  userLogin: (email: string, password: string) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_AWS_ENDPOINT}/user/authenticate`, { username: email, password: password })
      .then(res => {
        resolve(res.data);
      }).catch((error) => {
        //console.log("Error: ", error);
        reject(error);
      });
    });
  },  

  getSurveys: async (token: string) => {
    let surveyList = await Networking.getUserSurveys(token);
    let surveysByUser = await Promise.all(
      surveyList["surveys"].map((surveyItem) => 
        new Promise((resolve, reject) => {
          Networking.fetchSurvey(surveyItem.survey_hash)
          .then((survey) => {
            // Inject some additional data (has the user responded? and the short hash)
            resolve({
              ...survey,
              responded: surveyItem.responded,
              short_hash: surveyItem.short_hash,
              added_on: new Date(surveyItem.created_at * 1000)
            });
          }).catch(()=>{ reject() })
        })
      )
    );
    let surveysForUser = await Promise.all(
      surveyList["surveys_shared"].map((surveyItem) => 
        new Promise((resolve, reject) => {
          Networking.fetchSurvey(surveyItem.survey_hash)
          .then((survey) => {
            // Inject some additional data (has the user responded? and the short hash)
            resolve({
              ...survey,
              responded: surveyItem.responded,
              short_hash: surveyItem.short_hash,
              added_on: new Date(surveyItem.created_at * 1000)
            });     
          }).catch(()=>{ reject() })
        })
      )
    );
    return {
      surveys: surveysByUser,
      shared_surveys: surveysForUser
    }
  },

  getUserSurveys: (token: string) => {
    return new Promise<SurveyRequest>((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_AWS_ENDPOINT}/user/surveys?ac=${(new Date()).getTime()}`, {
        headers: { 'Authorization': `Bearer ${token}`}
      })
      .then(res => {
        //console.log(res.data);
        resolve(res.data);
      }).catch((error) => {
        //console.log("Error: ", error);
        reject(error);
      });
    });    
  },

  getSurveyResults: (token: string, surveyHash: string) => {
    return new Promise<SurveyResultsData>((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_AWS_ENDPOINT}/survey/results?ac=${(new Date()).getTime()}`, {
        headers: { 'Authorization': `Bearer ${token};${surveyHash}`}
      })
      .then(res => {
        console.log(res.data);
        resolve({...res.data, survey_hash: surveyHash}); // Just to be safe
      }).catch((error) => {
        //console.log("Error: ", error);
        reject(error);
      });
    });    
  },  

  sendRatings: (token: string, surveyHash: string, payload: Array<SurveyResponseItem>) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_AWS_ENDPOINT}/survey/answer`, payload, {
        headers: { 'Authorization': `Bearer ${token};${surveyHash}`}
      })
      .then(res => {
        console.log(res.data);
        resolve(res.data);
      }).catch((error) => {
        //console.log("Error: ", error);
        reject(error);
      });
    });
  },  

  associateByShortHash: (token: string, shortHash: string) => {
    return new Promise<string>((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_AWS_ENDPOINT}/survey/lookup`, {}, {
        headers: { 'Authorization': `Bearer ${token};${shortHash}`}
      })
      .then(res => {
        if (res.data.status) {
          resolve(res.data.survey_hash);
        } else {
          reject(false);
        }
      }).catch((error) => {
        reject(false);
      });
    });
  },    

  fetchTemplate: (templateName: string) => {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_ASSET_SERVER}/templates/${templateName}.json?ac=${(new Date()).getTime()}`)
      .then(res => {
        resolve(res.data);
      }).catch((error) => {
        reject(error);
      });
    });       
  },

  fetchTemplatesAtOnce: () => {
    return new Promise<TemplateType[]>((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_ASSET_SERVER}/templates/all.json?ac=${(new Date()).getTime()}`)
      .then(res => {
        resolve(res.data.templates);
      }).catch((error) => {
        reject(error);
      });
    })
  },

  fetchTemplates: async (token: string) => {

    // Temporary change for user-testing, so we can control which templates
    // show from server-side without having to release an update for the app.
    return (await Networking.fetchTemplatesAtOnce()).filter(t => t.enabled);

    /*
    let templates = await Networking.fetchAvailableTemplates(token); /*[
      /*
      "09894c23fa7332f0bb0877b89dd40e5a",
      "8264963fdc7692681c8a160ae742fb28",
      "e231a3bf1dc82c60466062dd62a5ad07",
      "f3658bff995562ecb8cd1b46a54b3590",
      "f3e956074143ee1a20811ba0223450cf",
    ];
    var templateResults = [];
    for (var i=0;i<templates.length;i++) {
      templateResults.push(await Networking.fetchTemplate(templates[i]));
    }
    return templateResults;
    */
  },

  fetchSurvey: (surveyHash: string) => {
    return new Promise<SurveyWithSectionsType>((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_ASSET_SERVER}/surveys/${surveyHash}.json?ac=${(new Date()).getTime()}`)
      .then(res => {
        resolve(res.data);
      }).catch((error) => {
        reject(error);
      });
    });       
  },  

  prepareUpload: (filesNeeded: number) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_AWS_ENDPOINT}/survey/prepare`, filesNeeded, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then(res => {
        resolve(res.data);
      }).catch((error) => {
        reject(error);
      });
    });       
  },

  blobFromPath: async (path: string): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      fetch(path).then((r) => {
        r.blob().then(data => resolve(data));
      })
    });
  },

  uploadFile: (file: string, uploadUrl: string) => {
    return new Promise((resolve, reject) => {
      Networking.blobFromPath(file)
      .then((fileData) => {
        axios.put(uploadUrl, fileData, { headers: { 'Content-Type': "image/jpg" } })
        .then(res => {
          resolve(res.data);
        }).catch((error) => {
          reject(error);
        });  
      }).catch((error) => {
        reject(error);
      })
    });       
  },  

  createSurvey: (userToken: string, survey: SurveyWithSectionsType) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_AWS_ENDPOINT}/survey/create`, {token: userToken, survey: survey }, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then(res => {
        resolve(res.data);
      }).catch((error) => {
        reject(error);
      });
    });       
  },

  adminAction: (userToken: string, surveyHash: string, action: number) => {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.REACT_APP_AWS_ENDPOINT}/user/aa`, {token: userToken, survey: surveyHash, action: action}, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then(res => {
        resolve(res.data);
      }).catch((error) => {
        reject(error);
      });
    });       
  },

};